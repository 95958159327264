import { getRequest, putRequest, postRequest } from "./apiCommunicationService"

export async function GetParticipantList(orgId, jwt) {
  const res = await getRequest(jwt, `api/Participants/${orgId}`)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to load participants")
  }

  if (res.status === 204) {
    return false
  }

  const data = await res.json()
  return data
}

export async function LockParticipant(payload, jwt) {
  const res = await postRequest(jwt, `api/Participants/Settings/lock`, payload)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to update participant settings (Lock)")
  }

  if (res.status === 204) {
    return false
  }

  const data = await res.json()
  return data
}

export async function SetParticipantSignInNote(payload, jwt) {
  const res = await postRequest(jwt, `api/Participants/Settings/signInNote`, payload)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to update participant settings (SignInNote)")
  }

  if (res.status === 204) {
    return false
  }

  const data = await res.json()
  return data
}
