import { getRequest, postRequest } from "./apiCommunicationService"

/* eslint-disable no-unused-vars */
export async function submitActivityLogin(groupId, UUID, jwt) {
  const res = await getRequest(jwt, `api/Activity/Login/${groupId}/${UUID}`)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to complete Activity-Check-In Login")
  }

  const data = await res.json()

  return data
}

export async function submitActivitySelection(data, jwt) {
  const res = await postRequest(jwt, `api/Activity/CheckIn`, data)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to submit Activity-Check-In")
  }

  return true
}
