import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useOktaAuth } from '@okta/okta-react';
import CustomMultiSelect from '../../CustomMultiSelect/CustomMultiSelect';
import { setConfig } from '../../../redux/reducers/organization';
import { UpdateOrganization, UploadOrgLogo } from '../../../services/organizationService';

function OrganizationSettings(props) {
  const { authState } = useOktaAuth();
  const { addToast } = useToasts();
  const { cardHeaderBGColor } = props;
  const {
    id,
    name,
    groupId: orgGroupId,
    logo,
    allowAnonymousActivityLogin,
    code,
  } = useSelector((state) => state.organization.config);

  // Try to extract size from logo-URL
  let tmpOrgLogo = logo;
  let tmpOrgLogoHeight = 100;
  if (logo) {
    const test = logo.substr(logo.lastIndexOf('/') + 1);
    if (!isNaN(test)) {
      tmpOrgLogoHeight = test;
      tmpOrgLogo = logo.substring(0, logo.lastIndexOf('/'));
    }
  }

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [orgName, setOrgName] = useState(name);
  const [orgLogo, setOrgLogo] = useState(tmpOrgLogo);
  const [orgLogoHeight, setOrgLogoHeight] = useState(tmpOrgLogoHeight);
  const [orgAllowAnonymous, setOrgAllowAnonymous] = useState(allowAnonymousActivityLogin);
  const minLogoHeight = 50;
  const maxLogoHeight = 300;
  const inputRef = React.createRef();

  function BuildRequestModel() {
    return {
      id,
      name: orgName,
      groupId: orgGroupId,
      logo: `${orgLogo}/${orgLogoHeight}`,
      allowAnonymousActivityLogin: orgAllowAnonymous,
      code,
    };
  }

  function Validate() {
    const requiredMessage = 'This field is required.';
    const validationErrors = {};

    if (!orgName) {
      validationErrors.name = requiredMessage;
    }

    if (!orgGroupId) {
      validationErrors.groupId = requiredMessage;
    }

    if (!orgLogo) {
      validationErrors.logo = requiredMessage;
    }

    if (!orgLogoHeight) {
      validationErrors.logoHeight = requiredMessage;
    }

    setErrors(validationErrors);
    return (Object.keys(validationErrors).length === 0);
  }

  function clearError(key) {
    delete errors[key];
    setErrors(errors);
  }

  async function SaveChanges() {
    setLoading(true);

    if (Validate()) {
      try {
        const requestModel = BuildRequestModel();
        await UpdateOrganization(requestModel, authState.accessToken.accessToken);
        setConfig(requestModel);

        addToast(
          '"Organization Settings" changes saved successfully',
          {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
      }
      catch (error) {
        console.error(error);
        addToast(
          'Unable to save "Organization Settings" changes. (See console for details)',
          {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
      }
    }

    setLoading(false);
  }

  function openFileBrowser() {
    inputRef.current?.click();
  }

  async function handleFileUpload() {
    if (inputRef.current?.files?.length > 0) {
      try {
        if (inputRef.current?.files[0].size > 262100) {
          const message = 'The selected file is too large. The maximum size limit is 256KB (Kilobytes)';
          addToast(
            message,
            {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 10000,
            },
          );
          return;
        }

        const formData = new FormData();
        formData.append('formFile', inputRef.current?.files[0]);
        formData.append('fileName', inputRef.current?.files[0].name);

        const logoRelativePath = await UploadOrgLogo(formData, authState.accessToken.accessToken);
        const kioskApiBaseUrl = process.env.REACT_APP_KIOSK_API_BASE_URL;
        const logoURL = `${kioskApiBaseUrl}/${logoRelativePath}`.replaceAll('"', '').replaceAll('\\\\', '/');

        setOrgLogo(logoURL);

        addToast(
          'File uploaded successfully',
          {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
      }
      catch (error) {
        console.error(error);
        addToast(
          'File upload failed, please try again later or contact your system administrator for assistance.',
          {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
      }
    }
  }

  return (
    <Card style={{ border: `1px solid ${cardHeaderBGColor}` }}>
      <Card.Header
        style={{
          backgroundColor: cardHeaderBGColor,
          color: 'white',
          marginRight: -1,
          marginTop: -1,
        }}
      >
        Organization Settings
      </Card.Header>
      <Card.Body>
        <Form noValidate>
          <Row>
            <Col md={12}>
              <Form.Text className="text-muted">
                This section allows you to change your Organization's details for Kiosk
              </Form.Text>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={orgName}
                  onChange={(e) => {
                    setOrgName(e.target.value);
                    clearError('name');
                  }}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  This is the name that displays on the home page of the Kiosk App.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Form.Group>
                <Form.Label>Logo</Form.Label>
                <Form.Control
                  type="text"
                  value={orgLogo}
                  onChange={(e) => {
                    setOrgLogo(e.target.value);
                    clearError('logo');
                  }}
                  isInvalid={!!errors.logo}
                />
                <div className="mt-1">
                  <input
                    ref={inputRef}
                    onChange={handleFileUpload}
                    className="d-none"
                    type="file"
                    accept=".jpg, .png, .jpeg|image/*"
                  />
                  <Button onClick={openFileBrowser}>Upload</Button>
                </div>
                <Form.Control.Feedback type="invalid">{errors.logo}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  This is the logo for your organization. Simply click the "Upload" button to
                  upload your own logo, or input any valid URL that points to an existing image
                  on the web.
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Row>
                  <Col md={7}>
                    <Form.Label>Logo Height</Form.Label>
                    <InputGroup>
                      <FormControl
                        type="number"
                        value={orgLogoHeight}
                        min={minLogoHeight}
                        max={maxLogoHeight}
                        onChange={(e) => {
                          setOrgLogoHeight(e.target.value);
                          clearError('logoHeight');
                        }}
                        isInvalid={!!errors.logoHeight}
                      />
                      <InputGroup.Text style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: 'none' }}>
                        px
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">{errors.logoHeight}</Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={11}>
                    <Form.Text className="text-muted">
                      Set the desired height for your logo. This changes how it's displayed on the
                      home page.
                      <br />
                      The min is 100 and the max 300 (pixels).
                      <br />
                      Leave blank for default (100)
                    </Form.Text>

                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Label>Activity Login</Form.Label>
              <CustomMultiSelect
                options={[
                  {
                    label: 'Allow Anonymous Activity Login',
                    key: 'allowAnonymousActivityLogin',
                  },
                ]}
                value={orgAllowAnonymous ? ['allowAnonymousActivityLogin'] : []}
                onChange={() => {
                  setOrgAllowAnonymous(!orgAllowAnonymous);
                }}
              />
              <Form.Text className="text-muted">
                Choose whether or not to allow anonymous "Activity Check-in" login for your
                organization using the "0000" unique ID on the check-in page
              </Form.Text>
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <Button
                className="mt-4"
                block
                onClick={SaveChanges}
              >
                {
                  loading
                  && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: 10, height: 22, width: 22 }}
                    />
                  )
                }
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default OrganizationSettings;
