/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setPath } from '../../redux/reducers/route';

import rl_logo from '../../images/rl-logo.png';

function KioskHeader() {
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.organization.config);
  let { logo } = useSelector((state) => state.organization.config);
  let logoHeight = 100;

  if (logo) {
    // Try to extract size from logo-URL
    const test = logo.substr(logo.lastIndexOf('/') + 1);
    if (!isNaN(test)) {
      logoHeight = test;
      logo = logo.substring(0, logo.lastIndexOf('/'));
    }
  }

  return (
    <Row style={{ textAlign: 'center' }}>
      <Col md={12}>

        {/* Load org logo */}
        {
          logo
          && (
            <img
              className="ratio-16-9 selectDisable"
              height={logoHeight}
              src={logo}
              alt="logo"
              style={{
                width: '95%',
                height: logoHeight,
                objectFit: 'contain',
              }}
              onClick={() => dispatch(setPath('home'))}
            />
          )
        }

        {/* Load default logo */}
        {
          !logo
          && (
            <img
              className="ratio-16-9 selectDisable"
              height={logoHeight}
              src={rl_logo}
              alt="logo"
              style={{
                width: '95%',
                height: logoHeight,
                objectFit: 'contain',
              }}
              onClick={() => dispatch(setPath('home'))}
            />
          )
        }

        <br />
        <br />

        <h3>
          {/* Prepend org name if available */}
          {
            name
            && <span>{`${name} `}</span>
          }
          Kiosk
        </h3>
        <br />
      </Col>
    </Row>
  );
}

export default KioskHeader;
