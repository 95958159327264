import React, { Component } from 'react';
import './CustomErrorFeedback.css';

class CustomErrorFeedback extends Component {
  render() {
    const { message } = this.props;

    return (
      <div className="feedback-container">
        {
          message
          && (
            <>
              <span className="feedback-message">{message}</span>
            </>
          )
        }
      </div>
    );
  }
}

export default CustomErrorFeedback;
