import React from 'react';
import { Spinner } from 'react-bootstrap';

function Authorizing() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '24vh' }}>
      <Spinner animation="border" style={{ width: 64, height: 64, marginBottom: 20 }} />
      <h3>Authorizing...</h3>
    </div>
  );
}

export default Authorizing;
