import React, { useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveLocation,setActiveLocationName } from '../../redux/reducers/organization';
import { setPath } from '../../redux/reducers/route';
import { getLocationCookieValues } from '../../helpers/cookies';
import { useCookies } from 'react-cookie';

const inputRef = React.createRef();

export default function OrgLocationSelector(){
    const [location, setLocation] = useState(undefined);
    const [locationName, setLocationName] = useState(undefined);
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({});
    const [, setCookie] = useCookies();

    const dispatch = useDispatch();
    const locations = useSelector((state) => state.organization.locations);

    if(locations?.length > 0 && location === undefined){
      setLocation(locations[0].id)
      setLocationName(locations[0].name)
    }

    const submitLocationSelection = () => {
        setLoading(true)
        dispatch(setActiveLocation(location))
        dispatch(setActiveLocationName(locationName));

        const locationCookie = getLocationCookieValues(location)
        setCookie(locationCookie.name, locationCookie.value, { expires: locationCookie.expiration });

        dispatch(setPath('home'));
    }

    function clearError(key) {
        delete errors[key];
        setErrors(errors);
      }


    return( 
<div style={{ textAlign: 'center', paddingTop: '20vh' }}>
      <Container>
        <Row className="row justify-content-center">
          <Col md={6}>
            <h5 className="mt-3" style={{ color: '#134563' }}>
              Please select the location for this Kiosk
            </h5>
          </Col>
        </Row>
        <Row className="row justify-content-center mt-4">
          <Col md={3}>
            <Form.Control
              ref={inputRef}
              size="lg"
              as="select"
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
              value={location}
              onChange={(e) => { 
                let selectedLocation = locations.find(location => location.id === e.target.value)
                setLocation(e.target.value); 
                setLocationName(selectedLocation?.name)
                clearError('location'); 
              }}
              isInvalid={!!errors.location}
              disabled={loading}
            >
                {locations.map((locationOption, index) => {return <option value={locationOption.id} key={index}>{locationOption.name}</option>})}
            </Form.Control>
            <Button
              className="mt-3"
              size="lg"
              block
              disabled={loading}
              onClick={submitLocationSelection}
            >
              {
                loading
                && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: 10, height: 22, width: 22 }}
                  />
                )
              }
              Select location
            </Button>
          </Col>
        </Row>
      </Container>    </div>)
}