import { createSlice } from '@reduxjs/toolkit';

export const firstTimeVisitorSlice = createSlice({
  name: 'firstTimeVisitor',
  initialState: {
    selectedOption: '',
  },
  reducers: {
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
  },
});

export const { setSelectedOption } = firstTimeVisitorSlice.actions;
export default firstTimeVisitorSlice.reducer;
