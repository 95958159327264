import React from 'react';
import { Image } from 'react-bootstrap';

import warningImg from '../../images/warning.png';

function OrgNotFound(props) {
  return (
    <div style={{ textAlign: 'center', paddingTop: '23vh' }}>
      <Image src={warningImg} rounded width={128} />
      {
        !props.uid
        && (
          <div>
            <h3>Organization UID not found</h3>
            <p>Please specify a unique-identifier (UID) in the URL</p>
          </div>
        )
      }
      {
        props.uid
        && (
          <div>
            <h3>Organization not found</h3>
            <p>Please specify a valid unique-identifier (UID) in the URL</p>
          </div>
        )
      }
    </div>
  );
}

export default OrgNotFound;
