import React, { useState } from "react";
import {
  Button,
  Card,
  Form,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
  faPlus,
  faSave,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useOktaAuth } from "@okta/okta-react";
import { useToasts } from "react-toast-notifications";
import Collapse from "antd/es/collapse/index";
import {
  GetByGroupKey,
  UpdateLocalVocabularies,
} from "../../../services/vocabularyService";
import VocabEditModal from "./VocabEditModal";
import VocabDeleteModal from "./VocabDeleteModal";

const { Panel } = Collapse;

function Vocabularies(props) {
  const { addToast } = useToasts();
  const { authState } = useOktaAuth();
  const { cardHeaderBGColor, localVocabs, setLocalVocabs } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [changedItems, setChangedItems] = useState([]);
  const { id } = useSelector((state) => state.organization.config);
  const [helpTextShown, setHelpTextShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const GetNewItem = () => ({
    id: uuidv4(), // New Guid
    organizationId: id,
    language: "en-us", // Only supported language for now
    groupKey: "",
    key: "",
    value: "",
    parentId: null,
    new: true,
  });

  function updateEditItem(key, value) {
    const tmpEditItem = JSON.parse(JSON.stringify(editItem)); // make a local copy

    // Clear parentId if group not 'activity-check-in-options'

    if (
      ![
        "activity-check-in-options",
        "first-time-visitor-option-descriptions",
      ].includes(tmpEditItem?.groupKey) &&
      tmpEditItem.parentId
    ) {
      tmpEditItem.parentId = "";
    }

    tmpEditItem[key] = value;
    setEditItem(tmpEditItem);
  }

  function addChangedItems(items, state) {
    const tmpChangedItems = JSON.parse(JSON.stringify(changedItems)).filter(
      (x) => !items.map((y) => y.id).includes(x.id)
    ); // make a local copy

    for (const item of items) {
      tmpChangedItems.push({
        ...item,
        state,
      });
    }

    setChangedItems(tmpChangedItems);
  }

  const defaultShowTipDelay = 1000;
  const defaultHideTipDelay = 100;
  const [upTipDelay, setUpTipDelay] = useState(defaultShowTipDelay);
  const [addTipDelay, setAddTipDelay] = useState(defaultShowTipDelay);
  const [saveTipDelay, setSaveTipDelay] = useState(defaultShowTipDelay);

  function toggleTooltipDelay(key, show) {
    // eslint-disable-next-line default-case
    switch (key) {
      case "up":
        setUpTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;

      case "add":
        setAddTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;

      case "save":
        setSaveTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;
    }
  }

  function updateLocalVocabs(updatedItem) {
    const tmpLocalVocabs = JSON.parse(JSON.stringify(localVocabs)); // make a local copy
    const tmpItem = tmpLocalVocabs.find((x) => x.id === updatedItem.id);
    let state = "modified";

    if (tmpItem) {
      // Edit
      tmpItem.groupKey = updatedItem.groupKey;
      tmpItem.key = updatedItem.key;
      tmpItem.value = updatedItem.value;
      tmpItem.parentId = updatedItem.parentId;
      tmpItem.modified = true;
      state = "modified";
    } else {
      // Add
      tmpLocalVocabs.unshift(updatedItem);
      state = "added";
    }

    tmpLocalVocabs.sort((a, b) =>
      a.groupKey > b.groupKey && a.key > b.key
        ? 1
        : b.groupKey > a.groupKey && b.key > a.key
        ? -1
        : 0
    );

    setLocalVocabs(tmpLocalVocabs);
    addChangedItems([updatedItem], state);
  }

  function deleteVocabItem(deletedItem, tmpLocalVocabs) {
    if (!tmpLocalVocabs) {
      tmpLocalVocabs = JSON.parse(JSON.stringify(localVocabs)); // make a local copy
    }

    // Get tmpLocalDeletedItem and update
    let tmpLocalDeletedItem = tmpLocalVocabs.find(
      (x) => x.id === deletedItem.id
    );
    if (tmpLocalDeletedItem) {
      tmpLocalDeletedItem.deleted = true; // flag as deleted;
    }

    // handle empty "value" - API requires "Value"
    if (!tmpLocalDeletedItem.value) tmpLocalDeletedItem.value = "deleted";

    // delete any children
    const children = localVocabs.filter(
      (x) => x.parentId === tmpLocalDeletedItem.id
    );

    for (const child of children) {
      tmpLocalVocabs = deleteVocabItem(child, tmpLocalVocabs);
    }

    setLocalVocabs(tmpLocalVocabs);
    addChangedItems([tmpLocalDeletedItem, ...children], "deleted");

    return tmpLocalVocabs;
  }

  async function SaveChanges() {
    try {
      setLoading(true);
      await UpdateLocalVocabularies(
        changedItems,
        authState.accessToken.accessToken
      );

      // Clear new for all
      const tmpLocalVocabs = JSON.parse(JSON.stringify(localVocabs)).filter(
        (x) => !x.deleted // exclude "deleted" items
      ); // make a local copy
      tmpLocalVocabs.forEach((vocab) => {
        delete vocab.new;
        delete vocab.modified;
      });
      setLocalVocabs(tmpLocalVocabs);

      // Clear changedItems
      setChangedItems([]);

      addToast("Changes saved successfully", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    } catch (error) {
      console.error(error);

      addToast("Unable to save changes. (See console for details)", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    }

    setLoading(false);
  }

  const buttonPanel = (
    <div
      style={{
        position: "fixed",
        bottom: 15,
        right: 15,
        zIndex: 999,
        textAlign: "right",
      }}
    >
      <OverlayTrigger
        delay={upTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("up", show)}
        overlay={<Tooltip>Back To Top</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="secondary"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </Button>
      </OverlayTrigger>

      <br />

      <OverlayTrigger
        delay={addTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("add", show)}
        overlay={<Tooltip>Add New Item</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="success"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            setEditItem(GetNewItem());
            setShowEdit(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </OverlayTrigger>

      <br className="d-none d-md-block" />

      <OverlayTrigger
        delay={saveTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("save", show)}
        overlay={<Tooltip>Save Changes</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="primary"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            SaveChanges();
          }}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: 10, height: 22, width: 22 }}
            />
          )}
          {!loading && <FontAwesomeIcon icon={faSave} />}
        </Button>
      </OverlayTrigger>
    </div>
  );

  function GetColorByState(item) {
    if (item.new) {
      return "#2ECC71";
    } else if (item.modified) {
      return "Blue";
    } else if (item.deleted) {
      return "Red";
    } else {
      return "";
    }
  }

  return (
    <div>
      {buttonPanel}

      <Collapse
        expandIconPosition="right"
        onChange={() => setHelpTextShown(!helpTextShown)}
        style={{ backgroundColor: "#AEB6BF" }}
      >
        <Panel
          header={
            <div>
              {helpTextShown ? "Hide " : "Show "}
              help-text
              <span className="ml-2" style={{ fontStyle: "italic" }}>
                (click to
                {helpTextShown ? " hide" : " show"})
              </span>
            </div>
          }
          key="1"
          style={{ borderBottom: "0.1px solid #D6D6D7" }}
        >
          <Form.Text className="text-muted" style={{ fontSize: 13 }}>
            This section allows you to customize the "Vocabulary" for you
            organization. These are text items that you can define, that get
            used throughout the application.
            <br />
            <br />
            Each Vocabulary item has the following properties:
            <ul>
              <li className="mt-1">
                <b>Group Key: </b>
                This allows you to group entries together. For example
                "activity-check-in-options", this group-key is used (and
                required) to group all "Activity Check-in" options together.
                "Activity Check-in" options defined outside of this group will
                not function correctly.
              </li>
              <li className="mt-1">
                <b>Key: </b>
                This is a unique key that identifies the specific vocabulary
                entry. This key needs to be globally unique for your
                organization.
              </li>
              <li className="mt-1">
                <b>Value: </b>
                This is the text value that the specific vocabulary entry holds.
                This is used for a variety of purposes, such as button text,
                select options and feedback messages.
              </li>
              <li className="mt-1">
                <b>Parent: </b>
                Each vocabulary entry can also (optionally) be linked to a
                parent entry. This allows you to define hierarchical structures
                of vocabulary entries. You can use this to define 2-3 levels of
                select options for "Activity Check-in" options.
              </li>
            </ul>
            This page allows you to perform the following actions per vocabulary
            item:
            <ul>
              <li className="mt-1">
                <b>Edit: </b>
                In each vocabulary entry row is an "Edit" button. This is a
                round blue button with a pencil icon. When you click this button
                the "Edit Vocabulary Item" popup is opened, where you can edit
                the values of the selected vocabulary item.
              </li>
              <li className="mt-1">
                <b>Delete: </b>
                In each vocabulary entry row is an "Delete" button. This is a
                round red button with a trash-can icon. When you click this
                button the "Delete Vocabulary Item" popup is opened, where you
                can confirm/cancel delete operation.
              </li>
            </ul>
            In the bottom-right corner are 3 buttons that perform the following
            actions:
            <ul>
              <li className="mt-1">
                <b>Back To Top: </b>
                This is a round grey button with an up-arrow icon. When clicked,
                this simply takes you to the top of the page.
              </li>
              <li className="mt-1">
                <b>Add: </b>
                This is a round green button with a plus icon. When clicked this
                open the "Add Vocabulary Item" popup.
              </li>
              <li className="mt-1">
                <b>Save: </b>
                This is a round blue button with a floppy-disc icon. This is
                probably the most important button on this page. This page
                batches (collects) all changes that you make until you click
                this save button. This also gives you an added layer of
                protection against accidental changes. To undo unsaved changes,
                simply reload the page without saving.
              </li>
            </ul>
          </Form.Text>
        </Panel>
      </Collapse>
      <br />

      {[...new Set(localVocabs?.map((x) => x.groupKey))].map((groupKey) => (
        <div key={groupKey}>
          <Card style={{ border: `1px solid ${cardHeaderBGColor}` }}>
            <Card.Header
              style={{
                backgroundColor: cardHeaderBGColor,
                color: "white",
                marginRight: -1,
                marginTop: -1,
              }}
            >
              Group Key:
              <u className="ml-2">{groupKey}</u>
            </Card.Header>
            <Card.Body style={{ padding: "15px 15px 0px 15px" }}>
              <Table bordered responsive="md" style={{ marginRight: 15 }}>
                <thead>
                  <tr style={{ backgroundColor: "#AEB6BF" }}>
                    <th>Key</th>
                    <th>Value</th>
                    <th>Parent</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {GetByGroupKey(localVocabs, groupKey).map((item) => (
                    <tr key={item.id}>
                      <td
                        style={{ width: "25%", color: GetColorByState(item) }}
                      >
                        {item.key}
                      </td>
                      <td
                        style={{ width: "37%", color: GetColorByState(item) }}
                      >
                        {item.value}
                      </td>
                      <td
                        style={{ width: "25%", color: GetColorByState(item) }}
                      >
                        {localVocabs.find((x) => x.id === item.parentId)?.key}
                      </td>
                      <td
                        style={{ width: "13%", color: GetColorByState(item) }}
                      >
                        <Button
                          variant="primary"
                          className="mr-1 mb-1"
                          style={{ borderRadius: 22, width: 40, height: 40 }}
                          onClick={() => {
                            setEditItem(item);
                            setShowEdit(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>
                        <Button
                          variant="danger"
                          className="mr-1 mb-1"
                          style={{ borderRadius: 22, width: 40, height: 40 }}
                          onClick={() => {
                            setEditItem(item);
                            setShowDelete(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <br />
        </div>
      ))}

      <VocabEditModal
        localVocabs={localVocabs}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        editItem={editItem}
        updateEditItem={updateEditItem}
        updateLocalVocabs={updateLocalVocabs}
      />

      <VocabDeleteModal
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        editItem={editItem}
        deleteVocabItem={deleteVocabItem}
      />
    </div>
  );
}

export default Vocabularies;
