/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  Col,
  Nav,
  Navbar,
  Row,
  Button,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import { Popover } from 'antd';
import { setPath } from '../../redux/reducers/route';

function TopNav() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [, removeCookie] = useCookies();

const requireLocation = useSelector((state) => state.organization.requireLocation)

  const [popoverVisible, setPopoverVisible] = useState(false);

  return (
    <Navbar fixed="top" className="top-nav" bg="white" expand="md">
      <Navbar.Brand>RecoveryLink</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
      {!requireLocation ?  
        <Nav className="mr-auto">
          <Nav.Link
            onClick={() => {
              dispatch(setPath('home'));
              if (location.pathname !== '/') history.push('/');
            }}
          >
            Home
          </Nav.Link>
        </Nav> : null}
        
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          <Popover
            placement="topLeft"
            content={(
              <Row>
                <Col>
                  <Button
                    variant="primary"
                    block
                    onClick={() => {
                      removeCookie('kiosk_unlocked');
                      removeCookie('kiosk_location');
                      history.go(0);
                    }}
                  >
                    Yes
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="secondary"
                    block
                    onClick={() => setPopoverVisible(false)}
                  >
                    No
                  </Button>
                </Col>
              </Row>
            )}
            title="Do you really want to lock Kiosk?"
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={(value) => setPopoverVisible(value)}
          >
            <FontAwesomeIcon
              icon={faUnlock}
              size="lg"
              style={{ color: '#5A6268', cursor: 'pointer' }}
            />
          </Popover>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default TopNav;
