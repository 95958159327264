import { Button, Col, Form, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import CustomMultiSelect from "../../../CustomMultiSelect/CustomMultiSelect";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { SetParticipantSignInNote } from "../../../../services/participantService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfo
  } from "@fortawesome/free-solid-svg-icons";

export default function ParticipantSignInNoteMOdal({showModal, onHideModal, participant}){
    const {
        id
      } = useSelector((state) => state.organization.config);
    const jwt = useSelector((state) => state.apiAuthentication.token);
    const { addToast } = useToasts();  

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [showSignInNote, setShowSignInNote] = useState(true)
    const [note, setNote] = useState("")

    useEffect(() => {
        if(showModal){
            if(!!participant?.settings){
                setShowSignInNote(participant.settings.showSignInNote !== undefined ? participant.settings.showSignInNote : true)
                setNote(participant.settings.signInNote || "")
            }else{
                setShowSignInNote(true)
                setNote("")
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participant])

    const clearError = (key) => {
        delete errors[key];
        setErrors(errors);
    }

    const Validate = () => {
        const requiredMessage = "This field is required.";
        const validationErrors = {};
    

        if(showSignInNote && !note){
            validationErrors.note = requiredMessage;
        }
        
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
      }

    const save = async () => {
        setIsLoading(true)

        try{
            if (Validate()) {
                const payload = {
                    organizationId: id,
                    participantIds: [participant.profile.participantId],
                    signInNote: note,
                    showSignInNote: showSignInNote,
                }
    
                const result = await SetParticipantSignInNote(payload, jwt)
    
                onHideModal(result)
                setIsLoading(false)
            }else{
                setIsLoading(false)
            }
        }catch(error){
            console.error(error);
            addToast(
                'Unable to update Participant settings. (See console for details)',
                {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 10000,
                },
            );
            setIsLoading(false)
        }
        
    }

    return (<Modal show={showModal} onHide={() => {onHideModal()}} centered>
    <Modal.Header>
        <Modal.Title>
            Sign in note
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form noValidate>
            <Col md={12}>
                <Form.Group>
                    <CustomMultiSelect 
                        options={[
                            {
                                label: 'Show note at next sign in',
                                key: 'show',
                            },
                            ]}
                        value={showSignInNote ? ['show'] : []}
                        onChange={() => {
                            setShowSignInNote(!showSignInNote);
                        }}/>
                        
                    <Form.Control
                        as="textarea" 
                        rows="5"
                        value={note}
                        onChange={(e) => {
                            setNote(e.target.value)
                            clearError("note");
                        }}
                        isInvalid={!!errors.note}
                        disabled={isLoading}
                        />
                    <Form.Control.Feedback type="invalid">
                        {errors.note}
                    </Form.Control.Feedback>   
                   
                   
                </Form.Group>

            </Col>
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <OverlayTrigger
            delay={100}
            placement="left"
            overlay={<Tooltip>Once the show note at next sign in is selected and saved, the note will show only once at the immediate next sign in and then deactivate</Tooltip>}
        >
            <Button
                style={{ 
                    borderRadius: 22, 
                    width: 40, 
                    height: 40, 
                    backgroundColor: "blue", 
                    borderColor: "blue", 
                    position: "absolute",
                    left: "25px"
                }}
            >
                <FontAwesomeIcon icon={faInfo} />
            </Button>
        </OverlayTrigger>
        <Button
            variant="primary"
            onClick={() => {
                setErrors({}); // Clear Errors
                save(false)
            }}
            style={{ width: 100, backgroundColor: "green" }}
            disabled={isLoading}
        >
            Save
        </Button>
        <Button
            variant="secondary"
            onClick={() => {
                setErrors({}); // Clear Errors
                onHideModal();
            }}
            style={{ width: 100 }}
            disabled={isLoading}
        >
            Cancel
        </Button>
    </Modal.Footer>
</Modal>)
}