import React from 'react';
import { Image } from 'react-bootstrap';

import warningImg from '../../images/warning.png';

function OrgLoadError() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '20vh' }}>
      <Image src={warningImg} rounded width={128} />
      <h3>Unable to load organization configuration</h3>
      <p>Please contact you system administrator for assistance.</p>
    </div>
  );
}

export default OrgLoadError;
