import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import PhoneInput from 'react-phone-input-2';
import { setPath } from '../../redux/reducers/route';
import KioskHeader from '../KioskHeader/KioskHeader';
import RequiredIndicator from '../RequiredIndicator/RequiredIndicator';
import CustomErrorFeedback from '../CustomErrorFeedback/CustomErrorFeedback';
import submitVolunteerSignUp from '../../services/volunteerSignUpService';
import { GetValueByKey } from '../../services/vocabularyService';
import CustomMultiSelect from '../CustomMultiSelect/CustomMultiSelect';
import { IsRequired_VolunteerSignupField } from '../../services/organizationService';
import { checkJWT } from '../../services/apiCommunicationService';
import { setToken } from "../../redux/reducers/apiAuthentication";
import { isValidEmail } from '../../helpers/validators';

function VolunteerSignUp() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const orgId = useSelector((state) => state.organization.config.id);
  const localVocabs = useSelector((state) => state.vocabularies.local);
  const jwt = useSelector((state) => state.apiAuthentication.token);
  const intakeCustomizations = useSelector(
    (state) => state.organization.intakeCustomizations?.filter((x) => x.intake === 'volunteer-sign-up'),
  );
  const location = useSelector((state) => state.organization.activeLocation)
  const locationName = useSelector((state) => state.organization.activeLocationName)

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [daysAvailable, setDaysAvailable] = useState([]);
  const [timeOfDayAvailable, setTimeOfDayAvailable] = useState([]);
  const [skillsAndInterests, setSkillsAndInterests] = useState('');

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  function validateForm() {
    const validationErrors = {};
    const requiredMessage = 'This field is required';
    const invalidMessage = (type) => `Invalid ${type}`;

    // firstName
    if (!firstName && IsRequired_VolunteerSignupField(intakeCustomizations, 'firstName')) {
      validationErrors.firstName = requiredMessage;
    }

    // lastName
    if (!lastName && IsRequired_VolunteerSignupField(intakeCustomizations, 'lastName')) {
      validationErrors.lastName = requiredMessage;
    }

    // email
    if (email === '' && IsRequired_VolunteerSignupField(intakeCustomizations, 'email')) {
      validationErrors.email = requiredMessage;
    }
    else if (email !== '' && !(isValidEmail(email))) {
      validationErrors.email = invalidMessage('email address');
    }

    // phone
    if (phone === '' && IsRequired_VolunteerSignupField(intakeCustomizations, 'phone')) {
      validationErrors.phone = requiredMessage;
    }

    // daysAvailable
    if (daysAvailable.length === 0 && IsRequired_VolunteerSignupField(intakeCustomizations, 'daysAvailable')) {
      validationErrors.daysAvailable = requiredMessage;
    }

    // timeOfDay
    if (timeOfDayAvailable.length === 0 && IsRequired_VolunteerSignupField(intakeCustomizations, 'timeOfDayAvailable')) {
      validationErrors.timeOfDay = requiredMessage;
    }

    // skillsAndInterests
    if (skillsAndInterests === '' && IsRequired_VolunteerSignupField(intakeCustomizations, 'skillsAndInterests')) {
      validationErrors.skillsAndInterests = requiredMessage;
    }

    setErrors(validationErrors); // Update state var
    return Object.keys(validationErrors).length === 0; // Return validation result
  }

  function BuildRequestModel() {
    return {
      organizationId: orgId,
      firstName,
      lastName,
      email,
      phone: phone !== '' ? `+1${phone.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+1', '')
        .replaceAll('+1', '')}` : '',
      daysAvailable: daysAvailable.join('|'),
      timesAvailable: timeOfDayAvailable.join('|'),
      skillsAndInterests,
      organizationLocationId: location,
      organizationLocationName: locationName
    };
  }

  async function submitForm(e) {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    if (validateForm()) {
      // Extra validation
      if (!phone && !email) {
        addToast(
          'Please supply either a phone number or email address to complete this form.',
          {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
        setLoading(false);
        return;
      }

      // Submit form data
      try {
        const model = BuildRequestModel();
        const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
        await submitVolunteerSignUp(model, localJWT);

        addToast(
          GetValueByKey(localVocabs, 'volunteer-sign-up-success'),
          {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );

        setLoading(false);

        // Redirect to home
        dispatch(setPath('home'));
      }
      catch (error) {
        // Log error
        console.error(error);
        addToast(
          GetValueByKey(localVocabs, 'volunteer-sign-up-failed'),
          {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );

        setLoading(false);
      }
    }
    else {
      addToast(
        GetValueByKey(localVocabs, 'volunteer-sign-up-form-errors'),
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );

      setLoading(false);
    }
  }

  function clearError(key) {
    delete errors[key];
    setErrors(errors);
  }

  return (
    <div>
      <KioskHeader />
      <br />

      <Form className="intake-form" onSubmit={submitForm} noValidate>
        <h5>Volunteer Sign-Up</h5>
        <div className="description-text mb-4">
          Please fill out the below information to be considered for volunteer sign-up at the
          organization. When you submit the form, staff will be notified and review your
          information. Once you are approved to volunteer, staff will contact you with next steps.
        </div>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'firstName')?.show
            && (
              <Col md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    {
                      IsRequired_VolunteerSignupField(intakeCustomizations, 'firstName')
                      && <RequiredIndicator />
                    }
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => { setFirstName(e.target.value); clearError('firstName'); }}
                    isInvalid={!!errors.firstName}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          {
            intakeCustomizations.find((x) => x.field === 'lastName')?.show
            && (
              <Col md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    {
                      IsRequired_VolunteerSignupField(intakeCustomizations, 'lastName')
                      && <RequiredIndicator />
                    }
                    Last Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => { setLastName(e.target.value); clearError('lastName'); }}
                    isInvalid={!!errors.lastName}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'email')?.show
            && (
              <Col md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    {
                      IsRequired_VolunteerSignupField(intakeCustomizations, 'email')
                      && <RequiredIndicator />
                    }
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); clearError('email'); }}
                    isInvalid={!!errors.email}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
          {
            intakeCustomizations.find((x) => x.field === 'phone')?.show
            && (
              <Col md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    {
                      IsRequired_VolunteerSignupField(intakeCustomizations, 'phone')
                      && <RequiredIndicator />
                    }
                    Phone
                  </Form.Label>
                  <PhoneInput
                    value={phone}
                    onlyCountries={['us']}
                    country="us"
                    disableCountryCode
                    placeholder=""
                    disableDropdown
                    inputStyle={{
                      width: '100%',
                      height: 38,
                      borderRadius: 3,
                      borderColor: errors.phone ? 'red' : '',
                    }}
                    onChange={(value) => { setPhone(value); clearError('phone'); }}
                    disabled={loading}
                  />
                  <CustomErrorFeedback message={errors.phone} />
                </Form.Group>
              </Col>
            )}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'daysAvailable')?.show
            && (
              <Col md={6} className="mb-3">
                <Form.Label>
                  {
                    IsRequired_VolunteerSignupField(intakeCustomizations, 'daysAvailable')
                    && <RequiredIndicator />
                  }
                  What days are you available to volunteer each week?
                </Form.Label>
                <CustomMultiSelect
                  options={[
                    {
                      label: 'Monday',
                      key: 'MON',
                    },
                    {
                      label: 'Tuesday',
                      key: 'TUE',
                    },
                    {
                      label: 'Wednesday',
                      key: 'WED',
                    },
                    {
                      label: 'Thursday',
                      key: 'THU',
                    },
                    {
                      label: 'Friday',
                      key: 'FRI',
                    },
                    {
                      label: 'Saturday',
                      key: 'SAT',
                    },
                    {
                      label: 'Sunday',
                      key: 'SUN',
                    },
                  ]}
                  value={daysAvailable}
                  onChange={(value) => setDaysAvailable(value)}
                  columns={3}
                  error={errors.daysAvailable}
                />
                <CustomErrorFeedback message={errors.daysAvailable} />
              </Col>)}
          {
            intakeCustomizations.find((x) => x.field === 'timeOfDayAvailable')?.show
            && (
              <Col md={6} className="mb-3">
                <Form.Label>
                  {
                    IsRequired_VolunteerSignupField(intakeCustomizations, 'timeOfDayAvailable')
                    && <RequiredIndicator />
                  }
                  What time of the day are you generally able to volunteer?
                </Form.Label>
                <CustomMultiSelect
                  options={[
                    {
                      label: 'Morning',
                      key: 'MORNING',
                    },
                    {
                      label: 'Afternoon',
                      key: 'AFTERNOON',
                    },
                    {
                      label: 'Evening',
                      key: 'EVENING',
                    },
                  ]}
                  value={timeOfDayAvailable}
                  onChange={(value) => setTimeOfDayAvailable(value)}
                  columns={3}
                  error={errors.timeOfDay}
                />
                <CustomErrorFeedback message={errors.timeOfDay} />
              </Col>
            )}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'skillsAndInterests')?.show
            && (
              <Col md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    {
                      IsRequired_VolunteerSignupField(intakeCustomizations, 'skillsAndInterests')
                      && <RequiredIndicator />
                    }
                    What are you most interesting in doing as a volunteer? What are your skills and interests?
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={skillsAndInterests}
                    onChange={(e) => { setSkillsAndInterests(e.target.value); clearError('skillsAndInterests'); }}
                    isInvalid={!!errors.skillsAndInterests}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.skillsAndInterests}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
        </Row>

        <Button
          type="submit"
          variant="primary"
        >
          {
            loading
            && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: 10, height: 22, width: 22 }}
              />
            )
          }
          Submit
        </Button>

      </Form>
    </div>
  );
}

export default VolunteerSignUp;
