import { postRequest } from "./apiCommunicationService"

/* eslint-disable no-unused-vars */
export default async function submitLog(
  groupId,
  locationId,
  participantId,
  participantKey,
  logType,
  message,
  jwt
) {
  const payload = {
    organizationId: groupId.toString(),
    participantId: participantId,
    organizationLocationId: locationId,
    participantKey: participantKey,
    type: logType,
    message: message,
  }

  const res = await postRequest(jwt, `api/Log/Create`, payload)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to submit UI Log")
  }
}
