/* eslint-disable no-unused-vars */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function CustomMultiSelect(props) {
  const {
    options,
    columns,
    onChange,
    value,
    error,
  } = props;

  function toggleChecked(option) {
    let newValue = value;
    if (value?.includes(option.key)) {
      newValue = value?.filter((x) => x !== option.key);
    }
    else {
      newValue = value?.concat([option.key]);
    }

    if (onChange) {
      onChange(newValue);
    }
  }

  const mdCol = (12 / columns) || 12;

  return (
    <Row
      style={{
        marginLeft: 0,
        marginRight: 0,
        paddingTop: 5,
        paddingBottom: 5,
        border: '1px solid gainsboro',
        borderRadius: 5,
      }}
    >
      {options.map((option) => (
        <Col
          md={mdCol}
          key={option.key}
          style={{
            paddingLeft: 4,
            paddingRight: 4,
          }}
        >
          <Button
            block
            variant=""
            style={{
              textAlign: 'left',
              marginTop: 3,
              marginBottom: 3,
              outline: 'none !important',
            }}
            onClick={() => toggleChecked(option)}
          >
            <table>
              <tbody>
                <tr valign="top">
                  <td>
                    {
                      value?.includes(option.key)
                        ? <FontAwesomeIcon icon={faCheckSquare} size="lg" style={{ marginRight: 10, color: '#0069D9' }} />
                        : <FontAwesomeIcon icon={faSquare} size="lg" style={{ marginRight: 10, color: (error ? 'red' : 'gray') }} />
                    }
                  </td>
                  <td>
                    {option.label}
                  </td>
                </tr>
              </tbody>
            </table>
          </Button>
        </Col>
      ))}
    </Row>
  );
}

export default CustomMultiSelect;
