import { createSlice } from '@reduxjs/toolkit';

export const actionMappingsSlice = createSlice({
  name: 'vocabularies',
  initialState: {
    actionMappings: null,
  },
  reducers: {
    setActionMappings: (state, action) => {
      state.actionMappings = action.payload;
    },
  },
});

export const {
  setActionMappings,
} = actionMappingsSlice.actions;

export default actionMappingsSlice.reducer;
