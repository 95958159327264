import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { LockParticipant } from "../../../../services/participantService";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import CustomMultiSelect from "../../../CustomMultiSelect/CustomMultiSelect";

export default function ParticipantLockModal({showModal, onHideModal, participant}){
    const {
        id
      } = useSelector((state) => state.organization.config);
    const jwt = useSelector((state) => state.apiAuthentication.token);
    const { addToast } = useToasts(); 

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [LockNoExpire, setLockNoExpire] = useState(true)
    const [lockDate, setLockDate] = useState(moment().add(1, 'days'))
    const [lockMessage, setLockMessage] = useState("Your profile is currently locked")

    useEffect(() => {
        if(showModal){
            if(!!participant?.settings){
                const participantLockDate = moment.utc(participant.settings.lockExpiry).local()

                setLockNoExpire(!participant.settings.lockExpiry)
                setLockDate(!!participant.settings.lockExpiry ? participantLockDate : moment().add(1, 'days'))
                setLockMessage(participant.settings.lockMessage)
            }else{
                setLockNoExpire(true)
                setLockDate(moment().add(1, 'days'))
                setLockMessage("Your profile is currently locked")
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participant])

    const clearError = (key) => {
        delete errors[key];
        setErrors(errors);
    }

    const Validate = (locked) => {
        const requiredMessage = "This field is required.";
        const validationErrors = {};
    
        if(!!locked){
            if(!LockNoExpire){
                if(!lockDate){
                    validationErrors.lockDate = "Please specify the expiry date of this lock";
                }else   if(lockDate < moment()){
                    validationErrors.lockDate = "The expiry date cannot be in the past";
                }
            }
        }
        

        // lockMessage
        if (!lockMessage) {
          validationErrors.lockMessage = requiredMessage;
        }
    
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
      }


    const save = async (doLock) => {
        setIsLoading(true)
        try{
            if (Validate(doLock)) {
            
                let lockDateLocal = moment(lockDate)
                lockDateLocal.hours(0)
                lockDateLocal.minutes(0)
                lockDateLocal.seconds(0)
                
    
                const payload = {
                    organizationId: id,
                    participantId: participant.profile.participantId,
                    locked: doLock,
                    lockMessage: lockMessage,
                    lockExpiry: !!doLock && !LockNoExpire ? lockDateLocal.utc().toISOString() : undefined
                }
    
                const result = await LockParticipant(payload, jwt)
    
                onHideModal([result])
                setIsLoading(false)
            }else{
                setIsLoading(false)
            }
        }catch(error){
            console.error(error);
            addToast(
                'Unable to update Participant settings. (See console for details)',
                {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 10000,
                },
            );
            setIsLoading(false)
        }
        
    }

    return(
        <Modal show={showModal} onHide={() => {onHideModal()}} centered>
            <Modal.Header>
                <Modal.Title>
                    Participant Lock setting
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Lock expiry date</Form.Label>
                            <CustomMultiSelect 
                                options={[
                                    {
                                        label: 'Lock does not expire',
                                        key: 'expire',
                                    },
                                    ]}
                                value={LockNoExpire ? ['expire'] : []}
                                onChange={() => {
                                setLockNoExpire(!LockNoExpire);
                                }}/>
                                {!LockNoExpire ?  
                                    <Form.Control
                                        type="date"
                                        min={moment().add(1, 'days').format("yyyy-MM-DD")}
                                        value={lockDate.format("yyyy-MM-DD")}
                                        onChange={(e) => {
                                            setLockDate(moment(e.target.valueAsDate))
                                            clearError("lockDate");
                                        }}
                                        isInvalid={!!errors.lockDate}
                                        disabled={isLoading || LockNoExpire}
                                    /> : null}
                           
                            <Form.Control.Feedback type="invalid">
                                {errors.lockDate}
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                The participant will not be able to login until the specified date. 
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Locked message</Form.Label>
                            <Form.Control
                                as="textarea" 
                                rows="5"
                                value={lockMessage}
                                onChange={(e) => {
                                    setLockMessage(e.target.value)
                                    clearError("lockMessage");
                                }}
                                isInvalid={!!errors.lockMessage}
                                disabled={isLoading}
                                />
                            <Form.Control.Feedback type="invalid">
                                {errors.lockMessage}
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                The participant will see this message when attempting to check in
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={() => {
                        setErrors({}); // Clear Errors
                        save(false)
                    }}
                    style={{ width: 125, backgroundColor: "green" }}
                    disabled={isLoading}
                >
                    Unlock profile
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        setErrors({}); // Clear Errors
                        save(true)
                    }}
                    style={{ width: 125, backgroundColor: "red" }}
                    disabled={isLoading}
                >
                    Lock profile
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setErrors({}); // Clear Errors
                        onHideModal();
                    }}
                    style={{ width: 100 }}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}