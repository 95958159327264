import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Row,
  Button,
  Spinner,
} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import AutoComplete from 'react-google-autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import { setPath } from '../../redux/reducers/route';
import KioskHeader from '../KioskHeader/KioskHeader';
import RequiredIndicator from '../RequiredIndicator/RequiredIndicator';
import CustomErrorFeedback from '../CustomErrorFeedback/CustomErrorFeedback';
import submitMemberIntake from '../../services/memberIntakeService';
import { GetByGroupKey, GetValueByKey } from '../../services/vocabularyService';
import CustomMultiSelect from '../CustomMultiSelect/CustomMultiSelect';
import { IsRequired_RCCIntakeField } from '../../services/organizationService';
import { setDrupalResponse, setOrigin } from "../../redux/reducers/additionalAssesments";
import { GetAdditionalAsessmentConfig } from "../../services/additionalAssessmentConfigService";
import AdditionalAssessmentModal from "../AdditionalAssesments/AdditionalAssessmentsModal";
import 'react-phone-input-2/lib/style.css';
import { v4 as uuidv4 } from 'uuid';
import { setSessionId } from '../../redux/reducers/activityCheckIn';
import { checkJWT } from '../../services/apiCommunicationService';
import { setToken } from "../../redux/reducers/apiAuthentication";
import submitLog from '../../services/loggerService';
import { getServiceConsentHelpLabel, getServiceConsentLabel } from '../../helpers/intakeConsentHelper';
import { isValidEmail } from '../../helpers/validators';

function RCCMemberIntake() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const googlePlaceApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const terms = useSelector((state) => state.vocabularies.remote);
  const { groupId, code } = useSelector((state) => state.organization.config);
  const localVocabs = useSelector((state) => state.vocabularies.local);
  const jwt = useSelector((state) => state.apiAuthentication.token);
  const intakeCustomizations = useSelector(
    (state) => state.organization.intakeCustomizations?.filter((x) => x.intake === 'rcc-intake'),
  );
  const location = useSelector((state) => state.organization.activeLocation)
  const locationName = useSelector((state) => state.organization.activeLocationName)

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [howHearAboutOrg, setHowHearAboutOrg] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [race, setRace] = useState([]);
  const [ethnicity, setEthnicity] = useState('');
  const [genderIdentity, setGenderIdentity] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [recoverySubstanceUse, setRecoverySubstanceUse] = useState('');
  const [recoverySubstanceUseDate, setRecoverySubstanceUseDate] = useState('');
  const [recoveryMentalHealth, setRecoveryMentalHealth] = useState('');
  const [recoveryMentalHealthDate, setRecoveryMentalHealthDate] = useState('');
  const [recoverySomethingElse, setRecoverySomethingElse] = useState('');
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [veteranStatus, setVeteranStatus] = useState('');
  const [personalIncomeLevel, setPersonalIncomeLevel] = useState('');
  const [housingStatus, setHousingStatus] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [selectAllThatApply, setSelectAllThatApply] = useState([]);
  const [treatedSubstanceUse, setTreatedSubstanceUse] = useState('');
  const [treatedMentalHealth, setTreatedMentalHealth] = useState('');
  const [address, setAddress] = useState('');
  const [addressFull, setAddressFull] = useState('');
  const [consentToContact, setConsentToContact] = useState([]);
  const [pronouns, setPronouns] = useState('');
  const [pronounsOther, setPronounsOther] = useState('');
  const [justiceSystemInvolvement, setJusticeSystemInvolvement] = useState('');
  const [peerServicesRequired, setPeerServicesRequired] = useState('');
  const [consentForServicesAndData, setConsentForServicesAndData] = useState('');
  const [consentForDataToBeShared, setConsentForDataToBeShared] = useState('');
  const [addiionalAssessmentsConfigs, setAdditionalAssessmentsConfigs] = useState([]);
  const { id } = useSelector((state) => state.organization.config);
  const [showAdditionalAssessments, setShowAdditionalAssessments] = useState(false);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(moment().utc());

  const inRecoveryValue = "In recovery"


  useEffect(() => {
    setStartTime(moment().utc());
    GetAdditionalAssessmentsConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAdditionalAssessmentsConfigs = async () => {
    const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
    const data = await GetAdditionalAsessmentConfig(id, localJWT);
    setAdditionalAssessmentsConfigs(data);
  };
  function getAge(dob) {
    const today = new Date();
    const nowYear = today.getFullYear();
    const nowMonth = today.getMonth();
    const nowDay = today.getDate();

    const birthYear = dob.getFullYear();
    const birthMonth = dob.getMonth();
    const birthDay = dob.getDate();

    let age = nowYear - birthYear;
    const age_month = nowMonth - birthMonth;
    const age_day = nowDay - birthDay;

    if (age_month < 0 || (age_month === 0 && age_day < 0)) {
      age = parseInt(age) - 1;
    }

    return age;
  }

  function validateForm() {
    const validationErrors = {};
    const requiredMessage = 'This field is required';
    const selectionRequiredMessage = 'A selection is required';
    const ageValidationError = `It appears as if you may be under the age of 13. 
      Please see a staff member for additional support`;
    const addressSelectionRequiredMessage = 'Please select your address from the auto-complete drop down to continue';
    const invalidMessage = (type) => `Invalid ${type}`;

    // firstName
    if (!firstName && IsRequired_RCCIntakeField(intakeCustomizations, 'firstName')) {
      validationErrors.firstName = requiredMessage;
    }

    // lastName
    if (!lastName && IsRequired_RCCIntakeField(intakeCustomizations, 'lastName')) {
      validationErrors.lastName = requiredMessage;
    }

    // email
    if (email === '' && IsRequired_RCCIntakeField(intakeCustomizations, 'email')) {
      validationErrors.email = requiredMessage;
    }
    else if (email !== '' && !(isValidEmail(email))) {
      validationErrors.email = invalidMessage('email address');
    }

    // phone
    if (phone === '' && IsRequired_RCCIntakeField(intakeCustomizations, 'phone')) {
      validationErrors.phone = requiredMessage;
    }

    // howHearAboutOrg
    if (!howHearAboutOrg && IsRequired_RCCIntakeField(intakeCustomizations, 'howHearAboutOrg')) {
      validationErrors.howHearAboutOrg = requiredMessage;
    }

    // organizationName
    const orgNameRequired = (howHearAboutOrg !== '15' && howHearAboutOrg !== '369');
    if (orgNameRequired && organizationName === '' && IsRequired_RCCIntakeField(intakeCustomizations, 'organizationName')) {
      validationErrors.organizationName = requiredMessage;
    }

    // dateOfBirth
    if (!dateOfBirth && IsRequired_RCCIntakeField(intakeCustomizations, 'dateOfBirth')) {
      validationErrors.dateOfBirth = requiredMessage;
    }

    if (dateOfBirth !== '' && getAge(new Date(dateOfBirth)) < 13) {
      validationErrors.dateOfBirth = ageValidationError;
    }

    // address
    if (!address && IsRequired_RCCIntakeField(intakeCustomizations, 'address')) {
      validationErrors.address = requiredMessage;
    }
    if (address !== '' && addressFull === '') {
      validationErrors.address = addressSelectionRequiredMessage;
    }

    // race
    if (!race && IsRequired_RCCIntakeField(intakeCustomizations, 'race')) {
      validationErrors.race = requiredMessage;
    }

    // pronouns
    if (pronouns === '' && IsRequired_RCCIntakeField(intakeCustomizations, 'pronouns')) {
      validationErrors.pronouns = requiredMessage;
    }

    // pronouns  -other
    if (pronouns === 'Other' && pronounsOther === '' && IsRequired_RCCIntakeField(intakeCustomizations, 'pronouns_other')) {
      validationErrors.pronounsOther = requiredMessage;
    }

    // justiceSystemInvolvement
    if (justiceSystemInvolvement === '' && IsRequired_RCCIntakeField(intakeCustomizations, 'justiceSystemInvolvement')) {
      validationErrors.justiceSystemInvolvement = requiredMessage;
    }

    // ethnicity
    if (!ethnicity && IsRequired_RCCIntakeField(intakeCustomizations, 'ethnicity')) {
      validationErrors.ethnicity = requiredMessage;
    }

    // genderIdentity
    if (!genderIdentity && IsRequired_RCCIntakeField(intakeCustomizations, 'genderIdentity')) {
      validationErrors.genderIdentity = requiredMessage;
    }

    // recoverySubstanceUse
    if (!recoverySubstanceUse && IsRequired_RCCIntakeField(intakeCustomizations, 'recoverySubstanceUse')) {
      validationErrors.recoverySubstanceUse = requiredMessage;
    }
    
    if(recoverySubstanceUse === inRecoveryValue && !recoverySubstanceUseDate){
      validationErrors.recoverySubstanceUseDate = requiredMessage;
    }

    // recoveryMentalHealth
    if (!recoveryMentalHealth && IsRequired_RCCIntakeField(intakeCustomizations, 'recoveryMentalHealth')) {
      validationErrors.recoveryMentalHealth = requiredMessage;
    }

    if(recoveryMentalHealth === inRecoveryValue && !recoveryMentalHealthDate){
      validationErrors.recoveryMentalHealthDate = requiredMessage;
    }

    // recoverySomethingElse
    if (!recoverySomethingElse && IsRequired_RCCIntakeField(intakeCustomizations, 'recoverySomethingElse')) {
      validationErrors.recoverySomethingElse = requiredMessage;
    }

    // employmentStatus
    if (!employmentStatus && IsRequired_RCCIntakeField(intakeCustomizations, 'employmentStatus')) {
      validationErrors.employmentStatus = requiredMessage;
    }

    // housingStatus
    if (!housingStatus && IsRequired_RCCIntakeField(intakeCustomizations, 'housingStatus')) {
      validationErrors.housingStatus = requiredMessage;
    }

    // educationLevel
    if (!educationLevel && IsRequired_RCCIntakeField(intakeCustomizations, 'educationLevel')) {
      validationErrors.educationLevel = requiredMessage;
    }

    // selectAllThatApply
    if (selectAllThatApply?.length === 0 && IsRequired_RCCIntakeField(intakeCustomizations, 'selectAllThatApply')) {
      validationErrors.selectAllThatApply = selectionRequiredMessage;
    }

    // treatedSubstanceUse
    if (!treatedSubstanceUse && IsRequired_RCCIntakeField(intakeCustomizations, 'treatedSubstanceUse')) {
      validationErrors.treatedSubstanceUse = requiredMessage;
    }

    // treatedMentalHealth
    if (!treatedMentalHealth && IsRequired_RCCIntakeField(intakeCustomizations, 'treatedMentalHealth')) {
      validationErrors.treatedMentalHealth = requiredMessage;
    }

    // consentForServicesAndData
    if (!consentForServicesAndData && IsRequired_RCCIntakeField(intakeCustomizations, 'consentForServicesAndData')) {
      validationErrors.consentForServicesAndData = requiredMessage;
    }

    // consentForDataToBeShared
    if (!consentForDataToBeShared && IsRequired_RCCIntakeField(intakeCustomizations, 'consentForDataToBeShared')) {
      validationErrors.consentForDataToBeShared = requiredMessage;
    }


    setErrors(validationErrors); // Update state var
    return Object.keys(validationErrors).length === 0; // Return validation result
  }

  function calcEndTime() {
    const startTimeMinutesSinceMidnight = startTime.diff(moment().utc().startOf('day'), 'seconds');
    const actualEndTimeMinutesSinceMidnight = moment().utc().diff(moment().utc().startOf('day'), 'seconds');

    // Limit duration to 8 Minutes minimum
    if ((actualEndTimeMinutesSinceMidnight - startTimeMinutesSinceMidnight) / 60 < 8) {
      const cappedEndTime = startTimeMinutesSinceMidnight + (8 * 60);
      return cappedEndTime;
    }

    return actualEndTimeMinutesSinceMidnight;
  }

  function buildRequestBody() {
    const data = {
      field_case_name: {
        given: firstName,
        family: lastName,
      },
      field_intake_source: {
        target_id: howHearAboutOrg || 0,
      },
      field_case_pronouns: {
        value: pronouns === 'Other' ? pronounsOther : pronouns,
      },
      field_demo_status_justice: {
        target_id: justiceSystemInvolvement,
      },
      case_services: {
        field_services_peer: {
          value: peerServicesRequired
        }
      },
      field_veteran_status: {
        value: veteranStatus
      },
      field_personal_income_level: {
        value: personalIncomeLevel
      },
      field_parent_involvement: [],
      field_session_engagement: {
        field_engagement_channel: {
          value: 'Other',
        },
        field_engagement_date: {
          value: moment().utc().format('YYYY-MM-DD'),
        },
        field_engagement_time: {
          from: startTime.diff(moment().utc().startOf('day'), 'seconds'),
          to: calcEndTime(),
        },
      },
    };

    if (email !== '') {
      data.field_case_email_address = {
        value: email,
      };
    }


    data.data_consent = {
      field_case_phi_consent: {
        value: consentForServicesAndData !== '' ? consentForServicesAndData : null
      },
      field_case_phi_share: {
        value: consentForDataToBeShared !== '' ? consentForDataToBeShared : null
      }
    }



    if (phone !== '') {
      data.field_case_phone = {
        field_phone_number: {
          value: `+1${phone.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+1', '')
            .replaceAll('+1', '')}`,
        },
        field_phone_primary: {
          value: 1,
        },
      };
    }

    if (organizationName !== '') {
      data.field_service_provider = {
        value: organizationName,
      };
    }

    if (race.length > 0) {
      data.field_demo_race = [
        race.map((value) => ({
          target_id: value,
        })),
      ];
    }

    if(ethnicity !== ""){
      data.field_demo_latinx_origin = {
        target_id: ethnicity,
      };
    }

    if(genderIdentity !== ""){
      data.field_demo_gender = {
        target_id: genderIdentity,
        value: genderIdentity
      };  
    }


      data.field_demo_age = {
        value: dateOfBirth,//Note: this field should always be required for in-takes
      };

    if (recoverySubstanceUse) {
      data.field_status_sud_recovery = {
        target_id: recoverySubstanceUse,
      };
    }

    if (recoverySubstanceUseDate && recoverySubstanceUse === inRecoveryValue) {
      data.field_status_sud_recovery_date = {
        value: recoverySubstanceUseDate,
      };
    }

    if (recoveryMentalHealth) {
      data.field_status_mhd_recovery = {
        target_id: recoveryMentalHealth,
        value: recoveryMentalHealth
      };
    }

    if (recoveryMentalHealthDate && recoveryMentalHealth === inRecoveryValue) {
      data.field_status_mhd_recovery_date = {
        value: recoveryMentalHealthDate,
      };
    }    

    if (recoverySomethingElse) {
      data.field_status_se_recovery = {
        target_id: recoverySomethingElse,
      };
    }

    if (employmentStatus) {
      data.field_demo_status_employment = {
        target_id: employmentStatus,
      };
    }

    if (housingStatus) {
      data.field_demo_status_housing = {
        target_id: housingStatus,
      };
    }

    if (educationLevel) {
      data.field_demo_education_level = {
        target_id: educationLevel,
      };
    }

    if (selectAllThatApply.includes('involvedInDrugDuiRecoveryCourt')) {
      data.field_parent_involvement.push(
        {
          target_id: 307,
        },
      );
    }

    if (selectAllThatApply.includes('involvedInChildFamilyProtectiveServices')) {
      data.field_parent_involvement.push(
        {
          target_id: 308,
        },
      );
    }

    if (treatedSubstanceUse) {
      data.field_status_sud_treatment = {
        target_id: treatedSubstanceUse,
      };
    }

    if (treatedMentalHealth) {
      data.field_status_mhd_treatment = {
        target_id: treatedMentalHealth,
      };
    }

    if (addressFull) {
      data.field_address = {
        field_address: {
          address_line1: `${addressFull?.address_components?.find((x) => x.types.includes('street_number'))?.long_name} `
            + `${addressFull?.address_components?.find((x) => x.types.includes('route'))?.long_name}`,
          address_line2: '',
          locality: addressFull?.address_components?.find((x) => x.types.includes('locality'))?.long_name,
          administrative_area: addressFull?.address_components?.find((x) => x.types.includes('administrative_area_level_1'))?.short_name,
          postal_code: addressFull?.address_components?.find((x) => x.types.includes('postal_code'))?.long_name,
          country_code: addressFull?.address_components?.find((x) => x.types.includes('country'))?.short_name,
          value: `${addressFull?.address_components?.find((x) => x.types.includes('street_number'))?.long_name}, ${addressFull?.address_components?.find((x) => x.types.includes('locality'))?.long_name}, `
            + `${addressFull?.address_components?.find((x) => x.types.includes('postal_code'))?.long_name}${addressFull?.address_components?.find((x) => x.types.includes('country'))?.short_name}`
        },
        field_address_primary: {
          value: 1,
        },
      };
    }

    // Add consent-to-contact value - default false, also if not shown
    data.field_consent_to_contact = {
      value: consentToContact.includes('consentToContact') ? 'Yes' : 'No',
    };

    data.field_service_provider_location_id = {value: location}
    data.field_service_provider_location_name = {value: locationName}

    return data;
  }
  function redirectToHome() {
    dispatch(setPath('home'));
  }
  async function submitForm(e) {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    if (validateForm()) {
      // Extra validation
      if (!phone && !email) {
        addToast(
          'Please supply either a phone number or email address to complete this form.',
          {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
        setLoading(false);
        return;
      }

      if(!consentForServicesAndData || consentForServicesAndData === "false"){
        addToast(
          "We are unable to complete your intake without a valid consent to store your data electronically.",
          {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 10000,
          }
        );
        let validationErrors = {}
        validationErrors.consentForServicesAndData = "We are unable to complete your intake without a valid consent to store your data electronically.";
        setErrors(validationErrors);
        
        setLoading(false);
        return;
      }

      // Submit form data
      try {
        const data = buildRequestBody();
        const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
        const res = await submitMemberIntake(data, groupId, localJWT);

        addToast(
          GetValueByKey(localVocabs, 'kiosk-rcc-intake-success'),
          {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
        dispatch(setSessionId(uuidv4()));
        dispatch(setDrupalResponse(res));
        dispatch(setOrigin("RCC-Member-Intake"));
        setLoading(false);
        const allowedSteps = addiionalAssessmentsConfigs?.map(i => i.step);
        if (addiionalAssessmentsConfigs.length > 0 && allowedSteps[0].find(i => i === "New Member Form")) {
          setShowAdditionalAssessments(true);
        }
        else {
          redirectToHome();
        }
      }
      catch (error) {
        // Log error
        console.error(error);
        let errMessage = [GetValueByKey(localVocabs, 'kiosk-rcc-intake-failed')];

        //Send log to API
        submitLog(groupId, location, undefined, undefined, "RCC Member Intake failure", error.message, jwt).catch((error) => {console.error("Unable to submit UI log ", error)})

        // Relay message from Drupal API or show default
        if (error.message.toLowerCase().includes('must be unique') || error.message.toLowerCase().includes('already in use')) {
          errMessage = [];
          if (error.message.toLowerCase().includes('phone')) {
            errMessage.push(GetValueByKey(localVocabs, 'kiosk-rcc-intake-duplicate-phone-error'));
          }
          if (error.message.toLowerCase().includes('email')) {
            errMessage.push(GetValueByKey(localVocabs, 'kiosk-rcc-intake-duplicate-email-error'));
          }
        }

        errMessage.forEach((msg) => {
          addToast(
            msg,
            {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 15000,
            },
          );
        });

        setLoading(false);
      }
    }
    else {
      addToast(
        GetValueByKey(localVocabs, 'kiosk-rcc-intake-form-errors'),
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );

      setLoading(false);
    }
  }

  function clearError(key) {
    delete errors[key];
    setErrors(errors);
  }

  return (
    <div>
      <KioskHeader />
      <br />

      <Form className="intake-form" onSubmit={submitForm} noValidate>
        <h5>New Member Form</h5>
        <br />

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'firstName')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'firstName')
                      && <RequiredIndicator />
                    }
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={firstName}
                    onChange={(e) => { setFirstName(e.target.value); clearError('firstName'); }}
                    isInvalid={!!errors.firstName}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}

          {
            intakeCustomizations.find((x) => x.field === 'lastName')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'lastName')
                      && <RequiredIndicator />
                    }
                    Last Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={lastName}
                    onChange={(e) => { setLastName(e.target.value); clearError('lastName'); }}
                    isInvalid={!!errors.lastName}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                {
                  IsRequired_RCCIntakeField(intakeCustomizations, 'email')
                  && <RequiredIndicator />
                }
                Email
              </Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => { setEmail(e.target.value); clearError('email'); }}
                isInvalid={!!errors.email}
                disabled={loading}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                {
                  IsRequired_RCCIntakeField(intakeCustomizations, 'phone')
                  && <RequiredIndicator />
                }
                Phone
              </Form.Label>
              <PhoneInput
                value={phone}
                onlyCountries={['us']}
                country="us"
                disableCountryCode
                placeholder=""
                disableDropdown
                inputStyle={{
                  width: '100%',
                  height: 38,
                  borderRadius: 3,
                  borderColor: errors.phone ? 'red' : '',
                }}
                onChange={(value) => { setPhone(value); clearError('phone'); }}
                disabled={loading}
              />
              <CustomErrorFeedback message={errors.phone} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'pronouns')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'pronouns')
                      && <RequiredIndicator />
                    }
                    Pronouns
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={pronouns}
                    onChange={(e) => { setPronouns(e.target.value); clearError('pronouns'); }}
                    isInvalid={!!errors.pronouns}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.pronouns
                      && Object.keys(terms.pronouns).map((key) => (
                        <option key={key} value={terms.pronouns[key]}>
                          {terms.pronouns[key]}
                        </option>
                      ))
                    }
                    <option value="Other">
                      Other
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.pronouns}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          {
            pronouns === 'Other'
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    <RequiredIndicator />
                    Pronouns - Other
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please specify here"
                    value={pronounsOther}
                    onChange={(e) => { setPronounsOther(e.target.value); clearError('pronounsOther'); }}
                    isInvalid={!!errors.pronounsOther}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.pronounsOther}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          }
        </Row>
        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'howHearAboutOrg')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'howHearAboutOrg')
                      && <RequiredIndicator />
                    }
                    How did you hear about our organization?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={howHearAboutOrg}
                    onChange={(e) => { setHowHearAboutOrg(e.target.value); clearError('howHearAboutOrg'); }}
                    isInvalid={!!errors.howHearAboutOrg}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.intake_source
                      && Object.keys(terms.intake_source).map((key) => (
                        <option key={terms.intake_source[key].tid} value={terms.intake_source[key].tid}>
                          {terms.intake_source[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.howHearAboutOrg}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          {
            (howHearAboutOrg !== '' && howHearAboutOrg !== '15' && howHearAboutOrg !== '369')
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    <RequiredIndicator />
                    Organization Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={organizationName}
                    onChange={(e) => { setOrganizationName(e.target.value); clearError('organizationName'); }}
                    isInvalid={!!errors.organizationName}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.organizationName}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )
          }
        </Row>

        <Row>
          <Col md={12}>
            <div className="optional-header">
              Questions marked with a red asterisk are required. All others are optional, but providing this data if you are able does help to ensure we are able to best serve
              you and our community by having insight into who we are serving on an ongoing basis.
            </div>
          </Col>
        </Row>
        {
          intakeCustomizations.find((x) => x.field === 'race')?.show
          && (
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'race')
                      && <RequiredIndicator />
                    }
                    Race
                  </Form.Label>
                  <CustomMultiSelect
                    options={!race ? [] : Object.keys(terms.race).map((key) => ({
                      label: terms.race[key].name,
                      key: terms.race[key].tid,
                    }))}
                    value={race}
                    onChange={(value) => {
                      setRace(value);
                      clearError('race');
                    }}
                    columns={3}
                    error={errors.race}
                    isInvalid={!!errors.race}
                  />
                  <CustomErrorFeedback message={errors.race} />
                </Form.Group>
              </Col>
            </Row>
          )}

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'ethnicity')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'ethnicity')
                      && <RequiredIndicator />
                    }
                    Ethnicity
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={ethnicity}
                    onChange={(e) => { setEthnicity(e.target.value); clearError('ethnicity'); }}
                    isInvalid={!!errors.ethnicity}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.hispanic_latinx_or_spanish_origi
                      && Object.keys(terms.hispanic_latinx_or_spanish_origi).map((key) => (
                        <option
                          key={terms.hispanic_latinx_or_spanish_origi[key].tid}
                          value={terms.hispanic_latinx_or_spanish_origi[key].tid}
                        >
                          {terms.hispanic_latinx_or_spanish_origi[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.ethnicity}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'genderIdentity')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'genderIdentity')
                      && <RequiredIndicator />
                    }
                    Gender Identity
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={genderIdentity}
                    onChange={(e) => { setGenderIdentity(e.target.value); clearError('genderIdentity'); }}
                    disabled={loading}
                    isInvalid={!!errors.genderIdentity}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.gender
                      && Object.keys(terms.gender).map((key) => (
                        <option key={terms.gender[key].tid} value={terms.gender[key].tid}>
                          {terms.gender[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.genderIdentity}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          {
            intakeCustomizations.find((x) => x.field === 'dateOfBirth')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'dateOfBirth')
                      && <RequiredIndicator />
                    }
                    Date of Birth
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={dateOfBirth}
                    onChange={(e) => { setDateOfBirth(e.target.value); clearError('dateOfBirth'); }}
                    isInvalid={!!errors.dateOfBirth}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">{errors.dateOfBirth}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'recoverySubstanceUse')?.show
            && (
              <>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      {
                        IsRequired_RCCIntakeField(intakeCustomizations, 'recoverySubstanceUse')
                        && <RequiredIndicator />
                      }
                      Do you identify as a person in recovery from substance use?
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={recoverySubstanceUse}
                      onChange={(e) => {
                          setRecoverySubstanceUse(e.target.value); 
                          clearError('recoverySubstanceUseDate');}
                        }
                      disabled={loading}
                      isInvalid={!!errors.recoverySubstanceUse}
                    >
                      <option value="" disabled>-- Select --</option>
                      {
                        terms?.status_sud_recovery_table
                        && Object.keys(terms.status_sud_recovery_table).map((key) => (
                          <option key={terms.status_sud_recovery_table[key].tid} value={terms.status_sud_recovery_table[key].tid}>
                            {terms.status_sud_recovery_table[key].name}
                          </option>
                        ))
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.recoverySubstanceUse}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </>
            )}
            {
            intakeCustomizations.find((x) => x.field === 'recoveryMentalHealth')?.show
            && (
              <>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      {
                        IsRequired_RCCIntakeField(intakeCustomizations, 'recoveryMentalHealth')
                        && <RequiredIndicator />
                      }
                      Do you identify as a person in recovery from mental health?
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={recoveryMentalHealth}
                      onChange={(e) => {setRecoveryMentalHealth(e.target.value); clearError('recoveryMentalHealthDate'); }}
                      disabled={loading}
                      isInvalid={!!errors.recoveryMentalHealth}
                    >
                      <option value="" disabled>-- Select --</option>
                      {
                        terms?.status_mhd_recovery_table
                        && Object.keys(terms.status_mhd_recovery_table).map((key) => (
                          <option key={terms.status_mhd_recovery_table[key].tid} value={terms.status_mhd_recovery_table[key].tid}>
                            {terms.status_mhd_recovery_table[key].name}
                          </option>
                        ))
                      }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.recoveryMentalHealth}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </>
            )}
        </Row>

        {(recoverySubstanceUse === inRecoveryValue || recoveryMentalHealth === inRecoveryValue) &&
        <Row>
        {
            recoverySubstanceUse === inRecoveryValue ? (
              <>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      {
                        recoverySubstanceUse === inRecoveryValue
                        && <RequiredIndicator />
                      }
                      When did your recovery from substance use start?
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={recoverySubstanceUseDate}
                      onChange={(e) => { setRecoverySubstanceUseDate(e.target.value); clearError('recoverySubstanceUseDate'); }}
                      isInvalid={!!errors.recoverySubstanceUseDate}
                      disabled={loading || recoverySubstanceUse !== inRecoveryValue}
                    />
                    <Form.Control.Feedback type="invalid">{errors.recoverySubstanceUseDate}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </>
            ) : <Col md={6}></Col>}
        {
            recoveryMentalHealth === inRecoveryValue
            && (
              <>                
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      {
                        recoveryMentalHealth === inRecoveryValue
                        && <RequiredIndicator />
                      }
                      When did your recovery from mental health start?
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={recoveryMentalHealthDate}
                      onChange={(e) => { setRecoveryMentalHealthDate(e.target.value); clearError('recoveryMentalHealthDate'); }}
                      isInvalid={!!errors.recoveryMentalHealthDate}
                      disabled={loading || recoveryMentalHealth !== inRecoveryValue}
                    />
                    <Form.Control.Feedback type="invalid">{errors.recoveryMentalHealthDate}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </>
            )}
        </Row>
        }

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'recoverySomethingElse')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'recoverySomethingElse')
                      && <RequiredIndicator />
                    }
                    Do you identify as a person in recovery from something else?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={recoverySomethingElse}
                    onChange={(e) => setRecoverySomethingElse(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.recoverySomethingElse}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      GetByGroupKey(localVocabs, 'kiosk-rcc-intake-recovery-se-options')
                        .map((option) => (
                          <option key={option.key} value={option.key}>
                            {option.value}
                          </option>
                        ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.recoverySomethingElse}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}

          {
            intakeCustomizations.find((x) => x.field === 'employmentStatus')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'employmentStatus')
                      && <RequiredIndicator />
                    }
                    Current Employment Status
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={employmentStatus}
                    onChange={(e) => setEmploymentStatus(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.employmentStatus}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.status_employment
                      && Object.keys(terms.status_employment).map((key) => (
                        <option key={terms.status_employment[key].tid} value={terms.status_employment[key].tid}>
                          {terms.status_employment[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.employmentStatus}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'personalIncomeLevel')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'personalIncomeLevel')
                      && <RequiredIndicator />
                    }
                    Current Personal Income
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={personalIncomeLevel}
                    onChange={(e) => setPersonalIncomeLevel(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.personalIncomeLevel}
                  >
                    <option value="">-- Select --</option>
                    <option value="$1-$9,999">$1-$9,999</option>
                    <option value="$10,000-$19,999">$10,000-$19,999</option>
                    <option value="$20,000-$29,999">$20,000-$29,999</option>
                    <option value="$30,000-$39,999">$30,000-$39,999</option>
                    <option value="$40,000-$49,999">$40,000-$49,999</option>
                    <option value="$50,000+">$50,000+</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.personalIncomeLevel}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}

          {
            intakeCustomizations.find((x) => x.field === 'housingStatus')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'housingStatus')
                      && <RequiredIndicator />
                    }
                    Current Housing Status
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={housingStatus}
                    onChange={(e) => setHousingStatus(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.housingStatus}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.status_housing
                      && Object.keys(terms.status_housing).map((key) => (
                        <option key={terms.status_housing[key].tid} value={terms.status_housing[key].tid}>
                          {terms.status_housing[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.housingStatus}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'educationLevel')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'educationLevel')
                      && <RequiredIndicator />
                    }
                    Current Education Level
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={educationLevel}
                    onChange={(e) => setEducationLevel(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.educationLevel}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.education_level
                      && Object.keys(terms.education_level).map((key) => (
                        <option key={terms.education_level[key].tid} value={terms.education_level[key].tid}>
                          {terms.education_level[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.educationLevel}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}

        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'veteranStatus')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'veteranStatus')
                      && <RequiredIndicator />
                    }
                    U.S. Military Veteran?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={veteranStatus}
                    onChange={(e) => setVeteranStatus(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.veteranStatus}
                  >
                    <option value="" >-- Select --</option>
                    <option value="Yes" >Yes</option>
                    <option value="No" >No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.veteranStatus}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          {
            intakeCustomizations.find((x) => x.field === 'justiceSystemInvolvement')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'justiceSystemInvolvement')
                      && <RequiredIndicator />
                    }
                    Current Justice System Involvement
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={justiceSystemInvolvement}
                    onChange={(e) => { setJusticeSystemInvolvement(e.target.value); clearError('justiceSystemInvolvement'); }}
                    isInvalid={!!errors.justiceSystemInvolvement}
                    disabled={loading}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.status_justice
                      && Object.keys(terms.status_justice).map((key) => (
                        <option key={terms.status_justice[key].tid} value={terms.status_justice[key].tid}>
                          {terms.status_justice[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.justiceSystemInvolvement}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
        </Row>
        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'selectAllThatApply')?.show
            && (
              <Col md={6} className="mb-3">
                <Form.Label>
                  {
                    IsRequired_RCCIntakeField(intakeCustomizations, 'selectAllThatApply')
                    && <RequiredIndicator />
                  }
                  Please select all that apply
                </Form.Label>
                <CustomMultiSelect
                  options={[
                    {
                      label: 'Currently involved in Drug/DUI/Recovery court',
                      key: 'involvedInDrugDuiRecoveryCourt',
                    },
                    {
                      label: 'Currently involved in Child/Family protective services',
                      key: 'involvedInChildFamilyProtectiveServices',
                    },
                  ]}
                  value={selectAllThatApply}
                  onChange={(value) => setSelectAllThatApply(value)}
                  error={errors.selectAllThatApply}
                />
                <CustomErrorFeedback message={errors.selectAllThatApply} />
              </Col>
            )}
        </Row>

        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'treatedSubstanceUse')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'treatedSubstanceUse')
                      && <RequiredIndicator />
                    }
                    Have you ever attended treatment for substance use in the past?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={treatedSubstanceUse}
                    onChange={(e) => setTreatedSubstanceUse(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.treatedSubstanceUse}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.status_sud_treatment_table
                      && Object.keys(terms.status_sud_treatment_table).map((key) => (
                        <option key={terms.status_sud_treatment_table[key].tid} value={terms.status_sud_treatment_table[key].tid}>
                          {terms.status_sud_treatment_table[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.treatedSubstanceUse}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}

          {
            intakeCustomizations.find((x) => x.field === 'treatedMentalHealth')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'treatedMentalHealth')
                      && <RequiredIndicator />
                    }
                    Have you ever attended treatment for mental health in the past?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={treatedMentalHealth}
                    onChange={(e) => setTreatedMentalHealth(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.treatedMentalHealth}
                  >
                    <option value="" disabled>-- Select --</option>
                    {
                      terms?.status_mhd_treatment_table
                      && Object.keys(terms.status_mhd_treatment_table).map((key) => (
                        <option key={terms.status_mhd_treatment_table[key].tid} value={terms.status_mhd_treatment_table[key].tid}>
                          {terms.status_mhd_treatment_table[key].name}
                        </option>
                      ))
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.treatedMentalHealth}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
        </Row>

        <Row>
          {
            (intakeCustomizations.find((x) => x.field === 'consentForServicesAndData')?.show || intakeCustomizations.find((x) => x.field === 'consentForDataToBeShared')?.show)
            && (
              <Col md={12}>
                <div className="optional-header">
                {getServiceConsentHelpLabel(code)}
                </div>
              </Col>)}

          {
            intakeCustomizations.find((x) => x.field === 'consentForServicesAndData')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'consentForServicesAndData')
                      && <RequiredIndicator />
                    }
                   {getServiceConsentLabel(code)}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={consentForServicesAndData}
                    onChange={(e) => setConsentForServicesAndData(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.consentForServicesAndData}
                  >
                    <option value="" >-- Select --</option>
                    <option value="true" >Yes</option>
                    <option value="false" >No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.consentForServicesAndData}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}

          {
            intakeCustomizations.find((x) => x.field === 'consentForDataToBeShared')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'consentForDataToBeShared')
                      && <RequiredIndicator />
                    }
                    Do you provide consent for your personal health information to be shared by this organization if requested for the delivery of services?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={consentForDataToBeShared}
                    onChange={(e) => setConsentForDataToBeShared(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.consentForDataToBeShared}
                  >
                    <option value="" >-- Select --</option>
                    <option value="true" >Yes</option>
                    <option value="false" >No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.consentForDataToBeShared}</Form.Control.Feedback>
                </Form.Group>
              </Col>)}
        </Row>
        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'address')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'address')
                      && <RequiredIndicator />
                    }
                    Address (if experiencing homelessness, provide the best address where
                    you might sleep most often)
                  </Form.Label>
                  <AutoComplete
                    required
                    className="placesAutoComplete"
                    style={{ borderColor: errors.address ? 'red' : '' }}
                    apiKey={googlePlaceApiKey}
                    options={{
                      types: ['geocode', 'establishment'],
                    }}
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                      clearError('address');
                    }}
                    onPlaceSelected={(place) => {
                      setAddress(place.formatted_address);
                      setAddressFull(place);
                      clearError('address');
                    }}
                    disabled={loading}
                  />
                  <CustomErrorFeedback message={errors.address} />
                </Form.Group>
              </Col>
            )}
        </Row>
        <Row>
          {
            intakeCustomizations.find((x) => x.field === 'peerServicesRequired')?.show
            && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    {
                      IsRequired_RCCIntakeField(intakeCustomizations, 'peerServicesRequired')
                      && <RequiredIndicator />
                    }
                    Are you also interested in signing up for peer services?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={peerServicesRequired}
                    onChange={(e) => setPeerServicesRequired(e.target.value)}
                    disabled={loading}
                    isInvalid={!!errors.peerServicesRequired}
                  >
                    <option value="" >-- Select --</option>
                    <option value="true" >Yes</option>
                    <option value="false" >No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.peerServicesRequired}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
        </Row>

        {
          intakeCustomizations.find((x) => x.field === 'consentToContact')?.show
          && (
            <Row>
              <Col md={12} className="mt-2 mb-4">
                <Form.Label>
                  Do you provide your consent to being contacted by our organization regarding
                  upcoming events, new, or other activities?
                </Form.Label>
                <CustomMultiSelect
                  options={[
                    {
                      label: `I consent to being contacted by your organization regarding upcoming 
                    events, new, or other activities`,
                      key: 'consentToContact',
                    },
                  ]}
                  value={consentToContact}
                  onChange={(value) => setConsentToContact(value)}
                  error={errors.consentToContact}
                />
                <CustomErrorFeedback message={errors.consentToContact} />
              </Col>
            </Row>
          )
        }

        <Button
          type="submit"
          variant="primary"
          disabled={loading}
        >
          {
            loading
            && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: 10, height: 22, width: 22 }}
              />
            )
          }
          Submit
        </Button>

      </Form>
      <AdditionalAssessmentModal
        show={showAdditionalAssessments}
        additionalAssessmentsConfigs={addiionalAssessmentsConfigs}
        setShow={setShowAdditionalAssessments}
        redirect={redirectToHome}
        name={`${firstName} ${lastName}`}
      />
    </div>
  );
}

export default RCCMemberIntake;
