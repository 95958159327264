import moment from "moment";
import { Button, Col, Modal, Row } from "react-bootstrap";

export default  function ActivityCheckInLockedModal({showModal, onHideModal, participant}){


    if(!participant){
        return null
    }

    return (
    <Modal show={showModal} onHide={() => {onHideModal()}} centered>
            <Modal.Header>
                <Modal.Title>
                    <div style={{color: "orange"}}>
                        Profile locked
                    </div>
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Col md={12}>
                <Row>
                {!!participant.settings?.lockMessage ? participant.settings.lockMessage :  "Profile locked"}
                </Row>
                <Row style={{marginTop: "10px"}}>

                {!!participant.settings?.lockExpiry ? `Unlock date: ${moment.utc(participant.settings?.lockExpiry).local().format("DD MMM YYYY")}` : ""}
                </Row>
            </Col>


            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        onHideModal();
                    }}
                    style={{ width: 100,  backgroundColor: "orange", borderColor: "orange" }}
                >
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    )
}