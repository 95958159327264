import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import TreeSelect from 'antd/es/tree-select/index';
import Select from 'antd/es/select/index';
import { GetByGroupKey } from '../../../services/vocabularyService';
import CustomErrorFeedback from '../../CustomErrorFeedback/CustomErrorFeedback';

const { TreeNode } = TreeSelect;
const { Option } = Select;

function ActivityCheckInActionMappingEditModal(props) {
  const {
    localVocabs,
    showEdit,
    setShowEdit,
    editItem,
    updateEditItem,
    updateLocalActionMappings,
    // delete specific
    showDelete,
    setShowDelete,
    deleteActionMappingItem,
  } = props;

  const [errors, setErrors] = useState({});

  function clearError(key) {
    delete errors[key];
    setErrors(errors);
  }

  function Validate() {
    const requiredMessage = 'A selection is required.';
    const validationErrors = {};

    // Source Action
    if (props.requiredFields?.includes('sourceAction') && !editItem?.sourceAction) {
      validationErrors.sourceAction = requiredMessage;
    }

    // Criteria
    if (props.requiredFields?.includes('criteria') && editItem?.criteria?.length === 0) {
      validationErrors.criteria = requiredMessage;
    }

    // Success Target Action
    if (props.requiredFields?.includes('successTargetAction') && !editItem?.successTargetAction) {
      validationErrors.successTargetAction = requiredMessage;
    }

    // Failure Target Action
    if (props.requiredFields?.includes('failureTargetAction') && !editItem?.failureTargetAction) {
      validationErrors.failureTargetAction = requiredMessage;
    }

    setErrors(validationErrors);
    return (Object.keys(validationErrors).length === 0);
  }

  function Save() {
    if (Validate()) {
      setShowEdit(false);
      updateLocalActionMappings(editItem);
    }
    else {
      // ...
    }
  }

  return (
    <Modal show={showEdit || showDelete} onHide={() => null} centered>
      <Modal.Header>
        <Modal.Title>
          {(showEdit && !showDelete) && (
            <>
              {editItem?.new ? 'Add ' : 'Edit '}
              Option Mapping
            </>
          )}
          {(!showEdit && showDelete) && (
            <>
              Are you sure you want to delete this item?
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Text className="text-muted mb-4" hidden={showDelete}>
            Please note, if you create your own vocabulary items, this form requires specific
            naming conventions for vocabulary items in order for them to show up here.
            <div className="mt-2" />
            "Source" Actions need to have "activity-check-in-options" as the Group-Key.
            <br />
            "Target" Message "Keys" need to start with "activity-check-in-submit-".
          </Form.Text>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Option</Form.Label>
                <TreeSelect
                  disabled={showDelete}
                  size="large"
                  style={{ width: '100%' }}
                  value={editItem?.sourceAction}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  allowClear
                  treeDefaultExpandAll
                  onChange={(value) => {
                    updateEditItem('sourceAction', value);
                    clearError('sourceAction');
                  }}
                >
                  {
                    GetByGroupKey(localVocabs, 'activity-check-in-options')?.filter((x) => !x.parentId)
                      .map((item) => (
                        <TreeNode key={item.key} value={item.key} title={item.value}>
                          {
                            localVocabs.filter((x) => x.parentId === item.id).map((childL1) => (
                              <TreeNode key={childL1.key} value={childL1.key} title={childL1.value}>
                                {
                                  localVocabs.filter((y) => y.parentId === childL1.id).map((childL2) => (
                                    <TreeNode key={childL2.key} value={childL2.key} title={childL2.value} />
                                  ))
                                }
                              </TreeNode>
                            ))
                          }
                        </TreeNode>
                      ))
                  }
                </TreeSelect>
                <CustomErrorFeedback message={errors.sourceAction} />
                <Form.Text className="text-muted" hidden={showDelete}>
                  Please select the initial action/option that, when selected, will cause a
                  success/failure message to be shown
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Criteria</Form.Label>
                <Select
                  disabled={showDelete}
                  size="large"
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  value={editItem?.criteria}
                  onChange={(value) => {
                    updateEditItem('criteria', value);
                    clearError('criteria');
                  }}
                >
                  <Option value="comprehensive">Engaged in Comp Services</Option>
                  {/* <Option value="brief_checkin">Engaged in brief check-in only services</Option> -- Removed for SOAR*/}
                  <Option value="discharged">Discharged</Option>
                  <Option value="drop_in">Drop-in referral</Option>
                  <Option value="rcc">RCC member only</Option>
                </Select>
                <CustomErrorFeedback message={errors.criteria} />
                <Form.Text className="text-muted" hidden={showDelete}>
                  Please select the criteria that needs to be met in order to 'succeed' or 'fail'
                  the source-action
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Success Message</Form.Label>
                <Select
                  disabled={showDelete}
                  size="large"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  value={editItem?.successTargetAction}
                  onChange={(value) => {
                    updateEditItem('successTargetAction', value);
                    clearError('successTargetAction');
                  }}
                >
                  {
                    localVocabs?.filter((x) => x.key.toLowerCase().startsWith('activity-check-in-submit'))
                      .map((item) => (
                        <Option key={item.key} value={item.key}>{item.value}</Option>
                      ))
                  }
                </Select>
                <CustomErrorFeedback message={errors.successTargetAction} />
                <Form.Text className="text-muted" hidden={showDelete}>
                  Please select the message that will be shown upon successful
                  fulfillment of the selected criteria (i.e. success)
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Failure Message</Form.Label>
                <Select
                  disabled={showDelete}
                  size="large"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  value={editItem?.failureTargetAction}
                  onChange={(value) => {
                    updateEditItem('failureTargetAction', value);
                    clearError('failureTargetAction');
                  }}
                >
                  {
                    localVocabs?.filter((x) => x.key.toLowerCase().startsWith('activity-check-in-submit'))
                      .map((item) => (
                        <Option key={item.key} value={item.key}>{item.value}</Option>
                      ))
                  }
                </Select>
                <CustomErrorFeedback message={errors.failureTargetAction} />
                <Form.Text className="text-muted" hidden={showDelete}>
                  Please select the message that will be shown upon failure to
                  fulfill the selected criteria (i.e. failure)
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={showDelete ? 'danger' : 'primary'}
          onClick={() => {
            setErrors({}); // Clear Errors
            if (showEdit && !showDelete) {
              Save();
            }
            else {
              setShowDelete(false);
              deleteActionMappingItem(editItem);
            }
          }}
          style={{ width: 100 }}
        >
          Ok
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setErrors({}); // Clear Errors
            if (setShowEdit) setShowEdit(false);
            if (setShowDelete) setShowDelete(false);
          }}
          style={{ width: 100 }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ActivityCheckInActionMappingEditModal;
