import {
  OverlayTrigger,
  Tooltip,
  Button,
  Spinner,
  Form,
  Card,
  Table,
} from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
  faPlus,
  faSave,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { useToasts } from "react-toast-notifications";
import Collapse from "antd/es/collapse/index";
import AdditionalAssessmentsConfigurationModal from "./AdditionalAssessmentsConfigurationModal";
import {
  SubmitAdditionalAssestmentConfig,
  GetAdditionalAsessmentConfig,
} from "../../../services/additionalAssessmentConfigService";
import { useEffect } from "react";
import { checkJWT } from '../../../services/apiCommunicationService';
import { setToken } from "../../../redux/reducers/apiAuthentication";
const { Panel } = Collapse;

const AdditionalAssessmentConfiguration = (props) => {
  const dispatch = useDispatch();
  const { cardHeaderBGColor } = props;
  const { id } = useSelector((state) => state.organization.config);
  const { forms } = useSelector((state) => state.additionalAssessments);
  const jwt = useSelector((state) => state.apiAuthentication.token);

  const { addToast } = useToasts();
  const [editItem, setEditItem] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changedItems, setChangedItems] = useState([]);
  const { authState } = useOktaAuth();
  const [localAdditionalAssessments, setLocalAdditionalAssessments] = useState(
    []
  );
  const [helpTextShown, setHelpTextShown] = useState(false);
  const defaultShowTipDelay = 1000;
  const defaultHideTipDelay = 100;
  const [upTipDelay, setUpTipDelay] = useState(defaultShowTipDelay);
  const [addTipDelay, setAddTipDelay] = useState(defaultShowTipDelay);
  const [saveTipDelay, setSaveTipDelay] = useState(defaultShowTipDelay);

  useEffect(() => {
    GetAdditionalAssessmentsConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAdditionalAssessmentsConfigs = async () => {
    const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
    const data = await GetAdditionalAsessmentConfig(id, localJWT);
    setLocalAdditionalAssessments(data);
  };
  const toggleTooltipDelay = (key, show) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case "up":
        setUpTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;

      case "add":
        setAddTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;

      case "save":
        setSaveTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;
    }
  };
  const GetNewItem = (additionalForm = undefined) => {
    return {
      id: uuidv4(),
      organizationId: id,
      additionalForm: additionalForm,
      description: "",
      step: undefined,
      required: true,
      toggle: true,
      recurrence: undefined,
      new: true,
    };
  };
  const SaveChanges = async () => {
    setLoading(true);
    try {
      await SubmitAdditionalAssestmentConfig(
        changedItems,
        authState.accessToken.accessToken
      );
      const tmpAdditionalAssessments = JSON.parse(
        JSON.stringify(localAdditionalAssessments)
      );
      tmpAdditionalAssessments.forEach((mapping) => {
        delete mapping.new;
      });
      setLocalAdditionalAssessments(tmpAdditionalAssessments);

      setChangedItems([]);

      addToast("Changes saved successfully", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    } catch (error) {
      console.error(error);
      addToast("Unable to save changes. (See console for details)", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    }
    setLoading(false);
  };
  const deleteAdditionalAssessmentConfig = (deletedItem) => {
    const tmpLocalAdditionalAssessments = JSON.parse(
      JSON.stringify(localAdditionalAssessments)
    ).filter((x) => x.id !== deletedItem.id);

    setLocalAdditionalAssessments(tmpLocalAdditionalAssessments);
    addChangedItem(deletedItem, "deleted");
  };
  const addChangedItem = (item, state) => {
    const tmpChangedItems = JSON.parse(JSON.stringify(changedItems)).filter(
      (x) => x.id !== item.id
    );

    tmpChangedItems.push({
      ...item,
      state,
    });

    setChangedItems(tmpChangedItems);
  };
  const updateEditItem = (key, value) => {
    const tmpEditItem = JSON.parse(JSON.stringify(editItem));
    tmpEditItem[key] = value;
    setEditItem(tmpEditItem);
  };
  const updateLocalAdditionalAssesments = (updatedItem) => {
    const tmpLocalAdditionalAssessmentsConfigs = JSON.parse(
      JSON.stringify(localAdditionalAssessments)
    );
    const tmpItem = tmpLocalAdditionalAssessmentsConfigs?.find(
      (x) => x.id === updatedItem.id
    );
    let state = "modified";

    if (tmpItem) {
      // Edit
      tmpItem.additionalForm = updatedItem.additionalForm;
      tmpItem.description = updatedItem.description;
      tmpItem.recurrence = updatedItem.recurrence;
      tmpItem.required = updatedItem.required;
      tmpItem.step = updatedItem.step;
      tmpItem.toggle = updatedItem.toggle;
      state = "modified";
    } else {
      // Add
      tmpLocalAdditionalAssessmentsConfigs.unshift(updatedItem);
      state = "added";
    }

    tmpLocalAdditionalAssessmentsConfigs.sort((a, b) =>
      a.groupKey > b.groupKey && a.key > b.key
        ? 1
        : b.groupKey > a.groupKey && b.key > a.key
        ? -1
        : 0
    );
    setLocalAdditionalAssessments(tmpLocalAdditionalAssessmentsConfigs);
    addChangedItem(updatedItem, state);
  };
  const buttonPanel = (
    <div
      style={{
        position: "fixed",
        bottom: 15,
        right: 15,
        zIndex: 999,
        textAlign: "right",
      }}
    >
      <OverlayTrigger
        delay={upTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("up", show)}
        overlay={<Tooltip>Back To Top</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="secondary"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </Button>
      </OverlayTrigger>

      <br />

      <OverlayTrigger
        delay={addTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("add", show)}
        overlay={<Tooltip>Add New Item</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="success"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            setEditItem(GetNewItem());
            setShowEdit(true);
            setAddingNew(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </OverlayTrigger>

      <br className="d-none d-md-block" />

      <OverlayTrigger
        delay={saveTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("save", show)}
        overlay={<Tooltip>Save Changes</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="primary"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            SaveChanges();
          }}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: 10, height: 22, width: 22 }}
            />
          )}
          {!loading && <FontAwesomeIcon icon={faSave} />}
        </Button>
      </OverlayTrigger>
    </div>
  );
  const steps = [
    { value: "New Member Form", key: "new-member-form" },
    { value: "Activity Check In", key: "activity-check-in" },
    { value: "Kiosk Brief Intake", key: "kiosk-brief-intake" },
  ];
  return (
    <div>
      {buttonPanel}
      <Collapse
        expandIconPosition="right"
        onChange={() => setHelpTextShown(!helpTextShown)}
        style={{ backgroundColor: "#AEB6BF" }}
      >
        <Panel
          header={
            <div>
              {helpTextShown ? "Hide " : "Show "}
              help-text
              <span className="ml-2" style={{ fontStyle: "italic" }}>
                (click to
                {helpTextShown ? " hide" : " show"})
              </span>
            </div>
          }
          key="1"
          style={{ borderBottom: "0.1px solid #D6D6D7" }}
        >
          <Form.Text className="text-muted" style={{ fontSize: 13 }}>
            This page allows you to setup your "Additional Assessments" forms
            options.
            <br />
            <br />
            Each Additional Assessment configuration has the following
            properties:
            <ul>
              <li className="mt-1">
                <b>Additional Assessment Form: </b>
                The option for which you can add a cofiguration to a specific
                "Additional Assessment Form".
              </li>
              <li className="mt-1">
                <b>Description: </b>
                The text you would like to show to the participant
                when prompted to fill out the additional assessment.
              </li>
              <li className="mt-1">
                <b>Perform At: </b>
                Determines when the Additional Assessment form is required.
              </li>
              <li className="mt-1">
                <b>Enabled: </b>
                Allow or not the actual configuration.
              </li>
              {/* <li className="mt-1">
                                <b>Recurrence: </b>
                                Determines a time lapse to request the Additional Assessmentn Form.
                            </li> */}
            </ul>
            This page allows you to perform the following actions per Additional
            Assessment configuration:
            <ul>
              <li className="mt-1">
                <b>Edit: </b>
                In each Additional Assessment configuration entry row is an
                "Edit" button. This is a round blue button with a pencil icon.
                When you click this button the "Edit Option Additional
                Assessment configuration" popup is opened, where you can edit
                the values of the selected Additional Assessment configuration.
              </li>
              <li className="mt-1">
                <b>Delete: </b>
                In each vocabulary entry row is an "Delete" button. This is a
                round red button with a trash-can icon. When you click this
                button the "Delete Additional Assessment configuration" popup is
                opened, where you can confirm/cancel delete operation.
              </li>
            </ul>
            In the bottom-right corner are 3 buttons that perform the following
            actions:
            <ul>
              <li className="mt-1">
                <b>Back To Top: </b>
                This is a round grey button with an up-arrow icon. When clicked,
                this simply takes you to the top of the page.
              </li>
              <li className="mt-1">
                <b>Add: </b>
                This is a round green button with a plus icon. When clicked this
                open the "Add Additional Assessment configuration" popup.
              </li>
              <li className="mt-1">
                <b>Save: </b>
                This is a round blue button with a floppy-disc icon. This is
                probably the most important button on this page. This page
                batches (collects) all changes that you make until you click
                this save button. This also gives you an added layer of
                protection against accidental changes. To undo unsaved changes,
                simply reload the page without saving.
              </li>
            </ul>
          </Form.Text>
        </Panel>
      </Collapse>
      <div>
        <Card style={{ border: `1px solid ${cardHeaderBGColor}` }}>
          <Card.Header
            style={{
              backgroundColor: cardHeaderBGColor,
              color: "white",
              marginRight: -1,
              marginTop: -1,
            }}
          >
            <u className="ml-2">Additional Assessments configurations</u>
          </Card.Header>
          <Card.Body style={{ padding: "15px 15px 0px 15px" }}>
            {localAdditionalAssessments?.length === 0 && (
              <div className="mb-3">No items</div>
            )}

            {localAdditionalAssessments?.length > 0 && (
              <Table bordered responsive="md" style={{ marginRight: 15 }}>
                <thead>
                  <tr style={{ backgroundColor: "#AEB6BF" }}>
                    <th>Additional Form</th>
                    <th>Description</th>
                    <th>Perform At</th>
                    <th>Enabled</th>
                    {/* <th>Recurrence</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {localAdditionalAssessments?.map((item) => {
                    let localActionMapping = localAdditionalAssessments?.find(
                      (x) => x.sourceAction === item.key
                    );
                    return (
                      <tr key={item.id}>
                        <td
                          style={{
                            width: "25%",
                            color: item.new ? "blue" : "",
                          }}
                        >
                          {item.additionalForm}
                        </td>
                        <td
                          style={{
                            width: "20%",
                            color: item.new ? "blue" : "",
                          }}
                        >
                          {item.description}
                        </td>
                        <td
                          style={{
                            width: "20%",
                            color: item.new ? "blue" : "",
                          }}
                        >
                          {item.step.map((i, index) => {
                            if (index !== item.step.length - 1) {
                              return i + ", ";
                            } else {
                              return i + " ";
                            }
                          })}
                        </td>
                        <td
                          style={{
                            width: "8%",
                            color: item.new ? "blue" : "",
                          }}
                        >
                          {item.toggle ? "Yes" : "No"}
                        </td>
                        {/* <td
                                                    style={{
                                                        width: "10%",
                                                        color: item.new ? "blue" : "",
                                                    }}
                                                >
                                                    {"No data"}
                                                </td> */}
                        <td
                          style={{
                            width: "10%",
                            color: item.new ? "blue" : "",
                          }}
                        >
                          <Button
                            variant="primary"
                            className="mr-1 mb-1"
                            style={{ borderRadius: 22, width: 40, height: 40 }}
                            disabled={localActionMapping ? false : true}
                            onClick={() => {
                              setEditItem(localActionMapping);
                              setShowEdit(true);
                              setAddingNew(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                          <Button
                            variant="danger"
                            className="mr-1 mb-1"
                            style={{ borderRadius: 22, width: 40, height: 40 }}
                            disabled={localActionMapping ? false : true}
                            onClick={() => {
                              setEditItem(localActionMapping);
                              setShowDelete(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </div>
      <AdditionalAssessmentsConfigurationModal
        forms={forms}
        showEdit={showEdit}
        addingNew={addingNew}
        setShowEdit={setShowEdit}
        editItem={editItem}
        updateEditItem={updateEditItem}
        updateLocalAdditionalAssesments={updateLocalAdditionalAssesments}
        requiredFields={[
          "additionalForm",
          "description",
          "step",
          "required",
          "toggle",
        ]}
        steps={steps}
        localAdditionalAssessments={localAdditionalAssessments}
      />

      <AdditionalAssessmentsConfigurationModal
        forms={forms}
        showDelete={showDelete}
        addingNew={false}
        setShowDelete={setShowDelete}
        editItem={editItem}
        deleteAdditionalAssessmentConfig={deleteAdditionalAssessmentConfig}
        steps={steps}
      />
    </div>
  );
};
export default AdditionalAssessmentConfiguration;
