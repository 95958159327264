import { createSlice } from "@reduxjs/toolkit";

export const activityCheckInSlice = createSlice({
  name: "activityCheckIn",
  initialState: {
    UUID: "",
    loginResponse: null,
  },
  reducers: {
    setUUIDAction: (state, action) => {
      state.UUID = action.payload;
    },
    setLoginResponse: (state, action) => {
      state.loginResponse = action.payload;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
  },
});

export const { setUUIDAction, setLoginResponse, setSessionId } =
  activityCheckInSlice.actions;

export default activityCheckInSlice.reducer;
