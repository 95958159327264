import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useOktaAuth } from '@okta/okta-react';
import * as changeCase from 'change-case';
import { GetOrgEmailConfig, UpdateEmailSettings } from '../../../services/organizationService';
import { checkJWT } from '../../../services/apiCommunicationService';
import { setToken } from "../../../redux/reducers/apiAuthentication"
import { isValidEmail } from '../../../helpers/validators';

function EmailSettings(props) {
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();
  const { addToast } = useToasts();
  const { id } = useSelector((state) => state.organization.config);
  const jwt = useSelector((state) => state.apiAuthentication.token);
  const { cardHeaderBGColor, context } = props;

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [emailApiKey, setEmailApiKey] = useState('');
  const [emailFromAddress, setEmailFromAddress] = useState('');
  const [emailToAddresses, setEmailToAddresses] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailMessage, setEmailMessage] = useState('');

  // Load org email config
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
    const orgEmailConfig = (await GetOrgEmailConfig(id, localJWT))?.find((x) => x.context === context);
    setEmailId(orgEmailConfig?.id);
    setEmailApiKey(orgEmailConfig?.apiKey);
    setEmailFromAddress(orgEmailConfig?.fromAddress);
    setEmailToAddresses(orgEmailConfig?.toAddresses);
    setEmailSubject(orgEmailConfig?.subject);
    setEmailMessage(orgEmailConfig?.message);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function BuildRequestModel() {
    return {
      id: emailId,
      organizationId: id,
      apiKey: emailApiKey,
      fromAddress: emailFromAddress,
      toAddresses: emailToAddresses,
      subject: emailSubject,
      message: emailMessage,
      context,
    };
  }

  function Validate() {
    const requiredMessage = 'This field is required.';
    const validationErrors = {};

    if (!emailToAddresses) {
      validationErrors.toAddresses = requiredMessage;
    }
    else if (emailToAddresses.split(',').filter((x) => !isValidEmail(x)).length > 0) {
      validationErrors.toAddresses = 'This field contains one or more invalid email addresses';
    }

    if (!emailSubject) {
      validationErrors.subject = requiredMessage;
    }

    if (!emailMessage) {
      validationErrors.message = requiredMessage;
    }

    setErrors(validationErrors);
    return (Object.keys(validationErrors).length === 0);
  }

  function clearError(key) {
    delete errors[key];
    setErrors(errors);
  }

  async function SaveChanges() {
    setLoading(true);

    if (Validate()) {
      try {
        const requestModel = BuildRequestModel();
        await UpdateEmailSettings(requestModel, authState.accessToken.accessToken);

        addToast(
          '"Email Settings" changes saved successfully',
          {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
      }
      catch (error) {
        console.error(error);
        addToast(
          'Unable to save "Email Settings" changes. (See console for details)',
          {
            appearance: 'error',
            autoDismiss: true,
            autoDismissTimeout: 10000,
          },
        );
      }
    }

    setLoading(false);
  }

  return (
    <Card style={{ border: `1px solid ${cardHeaderBGColor}` }}>
      <Card.Header
        style={{
          backgroundColor: cardHeaderBGColor,
          color: 'white',
          marginRight: -1,
          marginTop: -1,
        }}
      >
        Email Settings for
        {' '}
        <u>{`${changeCase.headerCase(context)}`}</u>
      </Card.Header>
      <Card.Body>
        <Form noValidate>
          <Row>
            <Col md={12}>
              <Form.Text className="text-muted">
                These email settings are used for sending
                {` ${changeCase.headerCase(context)} `}
                emails to your
                organizational designees
              </Form.Text>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={8}>
              <Form.Group>
                <Form.Label>To Address(es)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={emailToAddresses}
                  onChange={(e) => {
                    setEmailToAddresses(e.target.value);
                    clearError('toAddresses');
                  }}
                  isInvalid={!!errors.toAddresses}
                />
                <Form.Control.Feedback type="invalid">{errors.toAddresses}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Define who you want emails to be sent to. Emails are sent automatically and can
                  be sent to one or many people.
                  <br />
                  To send to more than one address simply add multiple addresses separated by a
                  comma. (Example: "john@domain.com,jane@domain2.com")
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Form.Group>
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  type="text"
                  value={emailSubject}
                  onChange={(e) => {
                    setEmailSubject(e.target.value);
                    clearError('subject');
                  }}
                  isInvalid={!!errors.subject}
                />
                <Form.Control.Feedback type="invalid">{errors.subject}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Define a subject for automated "
                  {`${changeCase.headerCase(context)}`}
                  " emails
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={emailMessage}
                  onChange={(e) => {
                    setEmailMessage(e.target.value);
                    clearError('message');
                  }}
                  isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Define a message that gets emailed along with the form data upon "
                  {`${changeCase.headerCase(context)}`}
                  ".
                  { context === "request-peer-services" && <span><br/><strong>Note:</strong> Removing the ParticipantID: [ParticipantID]) portion from this email body will not allow for receiving staff to know which new member intake is interested in peer services.<br /></span> }
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Button
                className="mt-2"
                block
                onClick={SaveChanges}
              >
                {
                  loading
                  && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: 10, height: 22, width: 22 }}
                    />
                  )
                }
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default EmailSettings;
