import { Button, Col, Modal, Row } from "react-bootstrap";
import { SetParticipantSignInNote } from "../../services/participantService";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

export default function ActivityCheckInNoteModal({showModal, onHideModal, participant}){
    const jwt = useSelector((state) => state.apiAuthentication.token);
    const { addToast } = useToasts();  

    if(!participant){
        return null
    }

    const onOkClick = () => {
        const payload = {
            organizationId: participant.settings.organizationId,
            participantIds: [participant.settings.participantId],
            signInNote: participant.settings?.signInNote,
            showSignInNote: false,
        }

        SetParticipantSignInNote(payload, jwt)
            .catch((error) => {
                console.error(error);
                addToast(
                    'Unable to update Participant settings. (See console for details)',
                    {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 10000,
                    },
                );
            })

        onHideModal()
    }

    return (
    <Modal show={showModal} onHide={() => {onHideModal()}} centered>
            <Modal.Header>
                <Modal.Title>
                    <div >
                        Sign in note
                    </div>
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Col md={12}>
                <Row>
                {!!participant.settings?.signInNote ? participant.settings?.signInNote :  "Note not found"}
                </Row>
            </Col>


            </Modal.Body>
            <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                    onClick={() => {
                        onOkClick();
                    }}
                    style={{ width: 100 }}
                >
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    )



}