export function getCookieExpiration() {
  return 24 * 60 * 60 * 1000 // 24 hours
}

export function getLocationCookieValues(value) {
  const expiration = getCookieExpiration()
  return {
    name: "kiosk_location",
    value: value,
    expiration: new Date(new Date().getTime() + expiration),
  }
}
