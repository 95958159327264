import React from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';

function VocabDeleteModal(props) {
  const {
    showDelete,
    setShowDelete,
    editItem,
    deleteVocabItem,
  } = props;

  return (
    <Modal show={showDelete} onHide={() => null} centered>
      <Modal.Header>
        <Modal.Title>Are you sure you want to delete this item?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Group Key</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={editItem?.groupKey}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Key</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={editItem?.key}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Value</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={editItem?.value}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Parent Id</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={editItem?.parentId}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            setShowDelete(false);
            deleteVocabItem(editItem);
          }}
          style={{ width: 100 }}
        >
          Delete
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowDelete(false)}
          style={{ width: 100 }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VocabDeleteModal;
