import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { useCookies } from 'react-cookie';
import LandingPage from './components/LandingPage/LandingPage';
import FirstTimeOptions from './components/FirstTimeVisitor/FirstTimeVisitorOptions';
import KioskBriefIntake from './components/FirstTimeVisitor/KioskBriefIntake';
import RCCMemberIntake from './components/FirstTimeVisitor/RCCMemberIntake';
import ActivityCheckInLogin from './components/ActivityCheckIn/ActivityCheckInLogin';
import VolunteerSignUp from './components/Volunteering/VolunteerSignUp';
import ActivityCheckInSelect from './components/ActivityCheckIn/ActivityCheckInSelect';
import OrgNotFound from './components/Organization/OrgNotFound';
import OrgLoadError from './components/Organization/OrgLoadError';
import OrgConfigLoad from './components/Organization/OrgConfigLoad';
import AdminPage from './components/Admin/AdminPage';
import { setPath } from './redux/reducers/route';
import Layout from './components/Layout/Layout';
import UnlockPage from './components/LandingPage/UnlockPage';

import './App.css';
import GuestSignUp from './components/FirstTimeVisitor/GuestSignUp';
import OrgLocationSelector from './components/Organization/OrgLocationSelector';

// Okta //
const HOST = window.location.host;
const CALLBACK_PATH = '/login/callback';
const ISSUER = `https://${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/default`;
const REDIRECT_URI = `${HOST.includes('localhost') ? 'http' : 'https'}://${HOST}${CALLBACK_PATH}`;
const SCOPES = 'openid profile email';

const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  window.location = originalUri;
};

const config = {
  issuer: ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
};

const oktaAuth = new OktaAuth(config);
// Okta //

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function App() {
  const dispatch = useDispatch();
  const path = useSelector((state) => state.route.path);
  const [lastPath, setLastPath] = useState('');
  const [locked, setLocked] = useState(true);
  const [cookies] = useCookies();
  const { id: orgId } = useSelector((state) => state.organization.config);

  if (lastPath !== path) {
    scrollToTop();
    setLastPath(path);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    // Check if locked
    if (cookies.kiosk_unlocked && cookies.kiosk_unlocked !== 'undefined') {
      setLocked(false); // Unlock Kiosk globally

      // Load org config
      dispatch(setPath('organization/load-config'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        locked
        && <UnlockPage />
      }
      {
        !locked
        && (
          <Router forceRefresh>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
              <Switch>
                <Route path="locked">
                  <UnlockPage />
                </Route>
                <Route path={CALLBACK_PATH} component={LoginCallback} />
                <SecureRoute path="/admin">
                  <Layout>
                    {/* admin-page */}
                    {
                      ((path === 'home' || path === '' || path === "organization/location") && orgId)
                      && <AdminPage />
                    }

                    {/* organization/load-config */}
                    {
                      path === 'organization/load-config'
                      && <OrgConfigLoad />
                    }

                    {/* org error */}
                    {
                      path === 'organization/load-error'
                      && <OrgLoadError />
                    }

                    {/* org not-found */}
                    {
                      path === 'organization/not-found'
                      && <OrgNotFound uid />
                    }
                  </Layout>
                </SecureRoute>
                <Route path="/">
                  <Layout>
                    {/* organization/load-config */}
                    {
                      path === 'organization/load-config'
                      && <OrgConfigLoad />
                    }

                    {/* organization/location */}
                    {path === "organization/location" && <OrgLocationSelector/>}

                    {/* org error */}
                    {
                      path === 'organization/load-error'
                      && <OrgLoadError />
                    }

                    {/* org not-found */}
                    {
                      (path === 'organization/not-found')
                      && <OrgNotFound uid />
                    }

                    {/* root || home */}
                    {
                      (path === 'home' && orgId)
                      && <LandingPage />
                    }

                    {/* first-time-visit/options */}
                    {
                      (path === 'first-time-visit/options' && orgId)
                      && <FirstTimeOptions />
                    }

                    {/* first-time-visit/brief-intake */}
                    {
                      (path === 'first-time-visit/brief-intake' && orgId)
                      && <KioskBriefIntake />
                    }
                    {/* first-time-visit/member-intake */}
                    {
                      (path === 'first-time-visit/member-intake' && orgId)
                      && <RCCMemberIntake />
                    }

                    {/* first-time-visit/guest-sign-up */}
                    {
                      (path === 'first-time-visit/guest-sign-up' && orgId)
                      && <GuestSignUp />
                    }

                    {/* activity-check-in/login */}
                    {
                      (path === 'activity-check-in/login' && orgId)
                      && <ActivityCheckInLogin />
                    }

                    {/* activity-check-in/select */}
                    {
                      (path === 'activity-check-in/select' && orgId)
                      && <ActivityCheckInSelect />
                    }

                    {/* volunteer/sign-up */}
                    {
                      (path === 'volunteer/sign-up' && orgId)
                      && <VolunteerSignUp />
                    }
                  </Layout>
                </Route>
              </Switch>
            </Security>
          </Router>
        )
      }
    </>
  );
}

export default App;
