import React, { useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { useCookies } from 'react-cookie';
import { GetOrgConfig } from '../../services/organizationService';
import logo from '../../images/rl-logo.png';
import { checkJWT } from '../../services/apiCommunicationService';
import { setToken } from "../../redux/reducers/apiAuthentication";
import { useDispatch, useSelector } from 'react-redux';

const unlockLifetime = 24 * 60 * 60 * 1000; // 24 hours
const inputRef = React.createRef();

function UnlockPage() {
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.apiAuthentication.token);
  const { addToast } = useToasts();
  const [unlockCode, setUnlockCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [, setCookie] = useCookies();

  async function TryUnlock() {
    setLoading(true);

    try {
      // Validate
      if (unlockCode === '') {
        setErrors({ unlockCode: 'Please enter a value' });
        setLoading(false);
      }
      else {
        const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
        // Try to unlock Kiosk with code
        const unlocked = await GetOrgConfig(unlockCode, localJWT);

        if (!unlocked) {
          addToast(
            'Invalid code. Please enter a valid code.',
            {
              appearance: 'error',
              autoDismiss: true,
              autoDismissTimeout: 10000,
            },
          );

          setLoading(false);
          setUnlockCode('');
          inputRef.current.focus();
        }
        else {
          // Unlock Kiosk
          const fiveFromNow = new Date(new Date().getTime() + unlockLifetime);
          setCookie('kiosk_unlocked', unlockCode, { expires: fiveFromNow });
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }
      }
    }
    catch (error) {
      console.error(error);

      // Show toast message
      addToast(
        'An error occurred while trying to unlock your Kiosk. Please contact your administrator if the error persists',
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );

      setLoading(false);
    }
  }

  function clearError(key) {
    delete errors[key];
    setErrors(errors);
  }

  return (
    <div style={{ textAlign: 'center', paddingTop: '20vh' }}>
      <Container>
        <Row className="row justify-content-center">
          <Col md={6}>
            <img
              className="ratio-16-9"
              src={logo}
              alt="logo"
              style={{
                width: '95%',
                height: 164,
                objectFit: 'contain',
              }}
            />
            <br />
            <h5 className="mt-3" style={{ color: '#134563' }}>
              Please enter your Organization's unique code to unlock Kiosk
            </h5>
          </Col>
        </Row>
        <Row className="row justify-content-center mt-4">
          <Col md={3}>
            <Form.Control
              ref={inputRef}
              size="lg"
              type="text"
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
              value={unlockCode}
              onChange={(e) => { setUnlockCode(e.target.value); clearError('unlockCode'); }}
              isInvalid={!!errors.unlockCode}
              disabled={loading}
            />
            <Form.Control.Feedback type="invalid">{errors.unlockCode}</Form.Control.Feedback>

            <Button
              className="mt-3"
              size="lg"
              block
              disabled={loading}
              onClick={TryUnlock}
            >
              {
                loading
                && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: 10, height: 22, width: 22 }}
                  />
                )
              }
              Unlock
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UnlockPage;
