import { createSlice } from '@reduxjs/toolkit';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    tokenData: null,
  },
  reducers: {
    setTokenData: (state, action) => {
      state.tokenData = action.payload;
    },
  },
});

export const {
  setTokenData,
} = adminSlice.actions;

export default adminSlice.reducer;
