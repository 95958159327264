import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Collapse from "antd/es/collapse/index";

const { Panel } = Collapse;

function VocabEditModal(props) {
  const {
    localVocabs,
    showEdit,
    setShowEdit,
    editItem,
    updateEditItem,
    updateLocalVocabs,
  } = props;

  const [errors, setErrors] = useState({});
  const [helpTextShown, setHelpTextShown] = useState(false);

  function Validate() {
    const requiredMessage = "This field is required.";
    const validationErrors = {};

    // Group Key
    if (!editItem?.groupKey) {
      validationErrors.groupKey = requiredMessage;
    }

    // Key
    if (!editItem?.key) {
      validationErrors.key = requiredMessage;
    }
    if (
      editItem?.new &&
      localVocabs.filter(
        (x) => x.key.toLowerCase() === editItem?.key.toLowerCase()
      ).length > 0
    ) {
      validationErrors.key = "This key already exists. Keys need to be unique";
    }

    // Value
    if (!editItem?.value) {
      validationErrors.value = requiredMessage;
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  }

  function clearError(key) {
    delete errors[key];
    setErrors(errors);
  }

  function Save() {
    if (Validate()) {
      setShowEdit(false);
      updateLocalVocabs(editItem);
    } else {
      // ...
    }
  }

  return (
    <Modal show={showEdit} onHide={() => null} centered>
      <Modal.Header>
        <Modal.Title>
          {editItem?.new ? "Add " : "Edit "}
          Vocabulary Item
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Group Key</Form.Label>
                <Form.Control
                  as="select"
                  value={editItem?.groupKey || ""}
                  onChange={(e) => {
                    updateEditItem("groupKey", e.target.value);
                    clearError("groupKey");
                  }}
                  isInvalid={!!errors.groupKey}
                >
                  <option disabled value="">
                    Please Select
                  </option>
                  {[...new Set(localVocabs?.map((x) => x.groupKey))].map(
                    (groupKey) => (
                      <option key={groupKey} value={groupKey}>
                        {groupKey}
                      </option>
                    )
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.groupKey}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  This allows you to group entries together. For example
                  "activity-check-in-options", this group-key is used (and
                  required) to group all "Activity Check-in" options together.
                  "Activity Check-in" options defined outside of this group will
                  not function correctly.
                  <br />
                  It's
                  <u className="ml-1 mr-1">recommended</u>
                  to stick to the default groups unless you know what you're
                  doing.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Key</Form.Label>
                <Form.Control
                  type="text"
                  value={editItem?.key || ""}
                  onChange={(e) => {
                    updateEditItem("key", e.target.value);
                    clearError("key");
                  }}
                  isInvalid={!!errors.key}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.key}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  This is a unique key that identifies the specific vocabulary
                  entry. This key needs to be globally unique for your
                  organization.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Value</Form.Label>
                <Form.Control
                  type="text"
                  value={editItem?.value || ""}
                  onChange={(e) => {
                    updateEditItem("value", e.target.value);
                    clearError("value");
                  }}
                  isInvalid={!!errors.value}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.value}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  This is the text value that the specific vocabulary entry
                  holds. This is used for a variety of purposes, such as button
                  text, select options and feedback messages.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          {[
            "activity-check-in-options",
            "first-time-visitor-option-descriptions",
          ].includes(editItem?.groupKey) && (
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Parent Key</Form.Label>
                  <Form.Control
                    as="select"
                    value={editItem?.parentId || ""}
                    onChange={(e) =>
                      updateEditItem(
                        "parentId",
                        localVocabs.find((x) => x.id === e.target.value)?.id
                      )
                    }
                  >
                    <option value="">None</option>
                    {localVocabs
                      ?.filter((x) => x.id !== editItem?.id)
                      .map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.key}
                        </option>
                      ))}
                  </Form.Control>
                  <Form.Text className="text-muted">
                    Each vocabulary entry can also (optionally) be linked to a
                    parent entry. This allows you to define hierarchical
                    structures of vocabulary entries. You can use this to define
                    2-3 levels of select options for "Activity Check-in"
                    options for example.
                  </Form.Text>
                  {["activity-check-in-options"].includes(
                    editItem?.groupKey
                  ) && (
                    <Collapse
                      className="mt-3"
                      expandIconPosition="right"
                      onChange={() => setHelpTextShown(!helpTextShown)}
                      style={{ backgroundColor: "#AEB6BF" }}
                    >
                      <Panel
                        header={
                          <div style={{ fontSize: 13 }}>
                            {helpTextShown ? "Hide " : "Show "}
                            additional info for 2nd/3rd level select options
                          </div>
                        }
                        key="1"
                      >
                        <Form.Text
                          className="text-muted"
                          style={{ fontSize: 12 }}
                        >
                          To create 2nd and 3rd levels of select options, simply
                          create all the options you want for the 2nd (or 3rd)
                          level, and for each of them set the Parent to the
                          corresponding option in the previous level you want
                          them to relate to.
                          <br />
                          For example: Assume the following option exists
                          "Recovery Meeting" (with key option-1). Now simply
                          create the options you want (with their own unique
                          keys), and set their Parents to "option-1"
                          <br />
                          <br />
                          After that, when "Recovery Meeting" is selected, a 2nd
                          drop-down will appear with all the related "child"
                          options. This works the same for a 3rd level.
                        </Form.Text>
                      </Panel>
                    </Collapse>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            setErrors({}); // Clear Errors
            Save();
          }}
          style={{ width: 100 }}
        >
          Ok
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setErrors({}); // Clear Errors
            setShowEdit(false);
          }}
          style={{ width: 100 }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VocabEditModal;
