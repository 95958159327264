import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import Select from 'antd/es/select/index';
import { GetByGroupKey } from '../../../services/vocabularyService';
import CustomErrorFeedback from '../../CustomErrorFeedback/CustomErrorFeedback';

const { Option } = Select;

function FirstTimeVisitorActionMappingEditModal(props) {
  const {
    localVocabs,
    showEdit,
    setShowEdit,
    addingNew,
    editItem,
    updateEditItem,
    updateLocalActionMappings,
    localActrionMappings,
    intakeTypes,
    // delete specific
    showDelete,
    setShowDelete,
    deleteActionMappingItem,
  } = props;

  const [errors, setErrors] = useState({});

  function clearError(key) {
    delete errors[key];
    setErrors(errors);
  }

  function Validate() {
    const requiredMessage = 'A selection is required.';
    const validationErrors = {};

    // Source Action
    if (props.requiredFields?.includes('sourceAction') && !editItem?.sourceAction) {
      validationErrors.sourceAction = requiredMessage;
    }

    // Success Target Action
    if (props.requiredFields?.includes('successTargetAction') && !editItem?.successTargetAction) {
      validationErrors.successTargetAction = requiredMessage;
    }

    setErrors(validationErrors);
    return (Object.keys(validationErrors).length === 0);
  }

  function Save() {
    if (Validate()) {
      setShowEdit(false);
      updateLocalActionMappings(editItem);
    }
    else {
      // ...
    }
  }

  return (
    <Modal show={showEdit || showDelete} onHide={() => null} centered>
      <Modal.Header>
        <Modal.Title>
          {(showEdit && !showDelete) && (
            <>
              {editItem?.new ? 'Add ' : 'Edit '}
              Option Mapping
            </>
          )}
          {(!showEdit && showDelete) && (
            <>
              Are you sure you want to delete this item?
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Text className="text-muted mb-4" hidden={showDelete}>
            Please note, if you create your own vocabulary items, this form requires specific
            naming conventions for vocabulary items in order for them to show up here.
            <div className="mt-2" />
            "Source" Actions need to have "first-time-visitor-options" as the Group-Key.
          </Form.Text>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Option</Form.Label>
                <Select
                  disabled={!addingNew}
                  size="large"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  value={editItem?.sourceAction}
                  onChange={(value) => {
                    updateEditItem('sourceAction', value);
                    clearError('sourceAction');
                  }}
                >
                  {
                    !addingNew && GetByGroupKey(localVocabs, 'first-time-visitor-options')?.map((item) => (
                      <Option key={item.key} value={item.key}>{item.value}</Option>
                    ))
                  }
                  {
                    addingNew && GetByGroupKey(localVocabs, 'first-time-visitor-options')?.map((item) => {
                      return (!localActrionMappings.find((x) => x.sourceAction === item.key) && <Option key={item.key} value={item.key}>{item.value}</Option>)
                    })
                  }
                </Select>
                <CustomErrorFeedback message={errors.sourceAction} />
                <Form.Text className="text-muted" hidden={showDelete}>
                  Please select the initial action/option that, when selected, will cause a
                  specific intake form to be shown
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Intake Form</Form.Label>
                <Select
                  disabled={showDelete}
                  size="large"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  value={editItem?.successTargetAction}
                  onChange={(value) => {
                    updateEditItem('successTargetAction', value);
                    clearError('successTargetAction');
                  }}
                >
                  {
                    intakeTypes?.map((item) => (
                      <Option key={item.key} value={item.key}>{item.value}</Option>
                    ))
                  }
                </Select>
                <CustomErrorFeedback message={errors.successTargetAction} />
                <Form.Text className="text-muted" hidden={showDelete}>
                  Please select the intake form that will be shown
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={showDelete ? 'danger' : 'primary'}
          onClick={() => {
            setErrors({}); // Clear Errors
            if (showEdit && !showDelete) {
              Save();
            }
            else {
              setShowDelete(false);
              deleteActionMappingItem(editItem);
            }
          }}
          style={{ width: 100 }}
        >
          Ok
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setErrors({}); // Clear Errors
            if (setShowEdit) setShowEdit(false);
            if (setShowDelete) setShowDelete(false);
          }}
          style={{ width: 100 }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FirstTimeVisitorActionMappingEditModal;
