import { createSlice } from "@reduxjs/toolkit"

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    UID: "",
    config: "",
    intakeCustomizations: [],
    locations: [],
    activeLocation: "",
    activeLocationName: "",
    requireLocation: true,
  },
  reducers: {
    setUID: (state, action) => {
      state.UID = action.payload
    },
    setConfig: (state, action) => {
      state.config = action.payload
    },
    setIntakeCustomizations: (state, action) => {
      state.intakeCustomizations = action.payload
    },
    setLocations: (state, action) => {
      state.locations = action.payload
    },
    setActiveLocation: (state, action) => {
      state.activeLocation = action.payload
      state.requireLocation = false
    },
    setActiveLocationName: (state, action) => {
      state.activeLocationName = action.payload
    },
  },
})

export const {
  setUID,
  setConfig,
  setIntakeCustomizations,
  setLocations,
  setActiveLocation,
  setActiveLocationName,
} = organizationSlice.actions

export default organizationSlice.reducer
