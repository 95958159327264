import React, { useEffect, useState } from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import EmailSettings from "./PageSections/EmailSettings";
import OrganizationSettings from "./PageSections/OrganizationSettings";
import Vocabularies from "./PageSections/Vocabularies";
import ActivityCheckInActionMapping from "./PageSections/ActivityCheckInActionMapping";
import FirstTimeVisitorActionMapping from "./PageSections/FirstTimeVisitorActionMapping";
import IntakeCustomization from "./PageSections/IntakeCustomization";
import Authorizing from "../Authorization/Authorizing";
import AdditionalAssessmentsConfiguration from "./PageSections/AdditionalAssessmentsConfiguration";
import Unauthorized from "../Authorization/Unauthorized";
import ParticipantListing from "./PageSections/ParticipantManagement/ParticipantListing";

function AdminPage() {
  const [tabsActiveKey, setTabsActiveKey] = useState("organization");
  const cardHeaderBGColor = "#1A5276";

  // Authorize user access to Kiosk Admin
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const { groupId } = useSelector((state) => state.organization.config);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  // Load local vocabs when ready
  const stateLocalVocabs = useSelector((state) => state.vocabularies.local);
  const [localVocabs, setLocalVocabs] = useState(null);
  if (stateLocalVocabs && !localVocabs) {
    setLocalVocabs(stateLocalVocabs);
  }

  // Load intake customizations when ready
  const stateIntakeCustomizations = useSelector(
    (state) => state.organization.intakeCustomizations
  );
  const [intakeCustomizations, setIntakeCustomizations] = useState(null);
  if (stateIntakeCustomizations && !intakeCustomizations) {
    setIntakeCustomizations(stateIntakeCustomizations);
  }

  function GetGroupIds() {
    let groupIds = [];
    try {
      if (userInfo) {
        if (userInfo.groupIds !== null && typeof userInfo.groupIds !== "undefined" && userInfo.groupIds !== "") {
          groupIds = userInfo.groupIds.split(",").map((x) => x.trim());
        }

        if (userInfo.groupId !== null && typeof userInfo.groupId !== "undefined") {
          groupIds.push(userInfo.groupId.toString());
        }
      }
    } catch (error) {
      console.warn("Unable to extract GroupID.", error);
    }

    return groupIds;
  }

  return (
    <div>
      {(userInfo === null || groupId === null) && <Authorizing />}
      {userInfo !== null &&
        groupId !== null &&
        !GetGroupIds().includes(groupId.toString()) &&
        !GetGroupIds().includes("0") && (
          <Unauthorized />
        )}
      {userInfo !== null &&
        (GetGroupIds().includes(groupId.toString()) || GetGroupIds().includes("0")) && (
          <Tabs
            activeKey={tabsActiveKey}
            onSelect={(eventKey) => {
              setTabsActiveKey(eventKey);
            }}
          >
            <Tab
              className="tab-page"
              eventKey="organization"
              title={<div style={{ color: "black" }}>Organization</div>}
            >
              <div className="tab-container">
                <OrganizationSettings cardHeaderBGColor={cardHeaderBGColor} />
              </div>
            </Tab>
            <Tab
              className="tab-page"
              eventKey="emailSettings"
              title={<div style={{ color: "black" }}>Email Settings</div>}
            >
              <div className="tab-container">
                <Form.Text className="text-muted">
                  This section allows you to change your Organization's email
                  settings for Kiosk.
                </Form.Text>
                <br />
                <EmailSettings
                  cardHeaderBGColor={cardHeaderBGColor}
                  context="volunteer-signup"
                />
                <br />
                <EmailSettings
                  cardHeaderBGColor={cardHeaderBGColor}
                  context="kiosk-brief-intake"
                />
                <br />
                <EmailSettings
                  cardHeaderBGColor={cardHeaderBGColor}
                  context="request-peer-services"
                />
              </div>
            </Tab>
            <Tab
              className="tab-page"
              eventKey="intake-customization"
              title={<div style={{ color: "black" }}>Intake Customization</div>}
            >
              <div className="tab-container">
                <IntakeCustomization
                  cardHeaderBGColor={cardHeaderBGColor}
                  intakeCustomizations={intakeCustomizations}
                  setIntakeCustomizations={setIntakeCustomizations}
                />
              </div>
            </Tab>
            <Tab
              eventKey="vocabularies"
              title={<div style={{ color: "black" }}>Vocabularies</div>}
              className="tab-page"
            >
              <div className="tab-container">
                <Vocabularies
                  cardHeaderBGColor={cardHeaderBGColor}
                  localVocabs={localVocabs}
                  setLocalVocabs={setLocalVocabs}
                />
              </div>
            </Tab>
            <Tab
              eventKey="activityCheckIn"
              title={<div style={{ color: "black" }}>Activity Check-in</div>}
              className="tab-page"
            >
              <div className="tab-container">
                <ActivityCheckInActionMapping
                  cardHeaderBGColor={cardHeaderBGColor}
                  localVocabs={localVocabs?.filter((x) =>
                    x.groupKey.toLowerCase().startsWith("activity-check-in")
                  )}
                />
              </div>
            </Tab>
            <Tab
              eventKey="firstTimeVisitors"
              title={<div style={{ color: "black" }}>First Time Visitors</div>}
              className="tab-page"
            >
              <div className="tab-container">
                <FirstTimeVisitorActionMapping
                  cardHeaderBGColor={cardHeaderBGColor}
                  localVocabs={localVocabs?.filter((x) =>
                    x.groupKey
                      .toLowerCase()
                      .startsWith("first-time-visitor-options")
                  )}
                />
              </div>
            </Tab>
            <Tab
              eventKey="additionalAssessments"
              title={<div style={{ color: "black" }}>Additional Assessments</div>}
            >
              <div className="tab-container">
                <AdditionalAssessmentsConfiguration cardHeaderBGColor={cardHeaderBGColor}
                />
              </div>
            </Tab>
            <Tab
              eventKey="participantList"
              title={<div style={{ color: "black" }}>Participant management</div>}
            >
              <div className="tab-container">
                <ParticipantListing cardHeaderBGColor={cardHeaderBGColor}
                />
              </div>
            </Tab>
          </Tabs>
        )}
    </div>
  );
}

export default AdminPage;
