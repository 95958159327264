import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import {
    Table,
    Spinner,
    Button,
    Tooltip,
    OverlayTrigger
  } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLockOpen,
    faLock,
    faCommentDots,
    faCommentAlt,
  } from "@fortawesome/free-solid-svg-icons";
import { GetParticipantList } from "../../../../services/participantService";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { checkJWT } from '../../../../services/apiCommunicationService'
import { setToken } from "../../../../redux/reducers/apiAuthentication"
import ParticipantLockModal from "./ParticipantLockModal";
import moment from "moment";
import ParticipantSignInNoteMOdal from "./ParticipantSignInNoteModal";

export default function ParticipantListing(){
    const {
        id
      } = useSelector((state) => state.organization.config);
    const { addToast } = useToasts();  
    const dispatch = useDispatch();
    const jwt = useSelector((state) => state.apiAuthentication.token);
      
    const [searchPhrase, setSearchPhrase] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [participants, setParticipants] = useState([])
    const [activeParticipant, setActiveParticipant] = useState(undefined)
    const [showLockModal, setShowLockModal] = useState(false)
    const [showNoteModal, setShowNoteModal] = useState(false)

    const getParticipants = useCallback(async () => {
        setIsLoading(true)

        try{
            const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})

            const participantList = await GetParticipantList(id, localJWT)

            if(!!participantList?.participants){
                participantList.participants.forEach(participant => participant.fullName = `${participant.profile?.firstName} ${participant.profile?.lastName}`)

                setParticipants(participantList.participants)

            }else{
                setParticipants([])
            }

            setIsLoading(false)
        }catch (error){
            console.error(error);
            addToast(
                'Unable to load Participant list. (See console for details)',
                {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 10000,
                },
            );
            setParticipants([])
            setIsLoading(false)
            }
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    useEffect(() => {
        getParticipants()
    }, [getParticipants])

    const updateLocalParticipantSettings = (participantSettings) => {
        if(!!participantSettings && participantSettings.length > 0){
            let participantList = [...participants]

            participantList.forEach(x => { 
                const updated = participantSettings.find(setting => setting.participantId === x.profile.participantId)

                if(!!updated){
                    x.settings = updated
                }
            })
            setParticipants(participantList)
    
        }

        setActiveParticipant(undefined)
    }



    if(isLoading){
        return (   
        <div>
            <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            style={{ marginRight: 10, height: 22, width: 22 }}
          />
        </div>        
        )
    }else{
        return ( 
            <>
                <ParticipantLockModal 
                    showModal={showLockModal} 
                    onHideModal={(participant) => {
                            updateLocalParticipantSettings(participant)
                            setShowLockModal(false)
                        }} 
                    participant={activeParticipant}
                />
                <ParticipantSignInNoteMOdal 
                    showModal={showNoteModal}
                    onHideModal= {(participant) => {
                        updateLocalParticipantSettings(participant)
                        setShowNoteModal(false)
                    }}
                    participant={activeParticipant}
                />
                <div style={{marginBottom: "10px"}}>
                    <label style={{marginRight: "10px", fontWeight:"bold"}}> Search participants by name:  </label>
                    <input type="text" onChange={(e) => setSearchPhrase(e.target.value)} />
  
                </div>
                <Table bordered responsive="md" style={{ marginRight: 15 }}>
                    <thead>
                        <tr style={{ backgroundColor: "#AEB6BF" }}>
                            <th>First name</th>
                            <th>Last Name</th>
                            <th>Settings</th>
                        </tr>
                    </thead>
                    <tbody>
                    {participants?.filter(participant => searchPhrase === "" || 
                                                         participant.fullName?.includes(searchPhrase))
                        .sort((a, b) => (a.fullName > b.fullName) ? 1 : ((b.fullName > a.fullName) ? -1 : 0))
                        .map((participant) => {
                        let participantIsLocked = !!participant.settings?.locked
                        const expiryDate = !!participant.settings?.lockExpiry ? moment.utc(participant.settings?.lockExpiry).local() : undefined
                        const hasActiveNote = !!participant.settings?.showSignInNote

                        if(participantIsLocked && !!expiryDate){                            
                            participantIsLocked = expiryDate > moment()    
                        }

                        return (
                        <tr key={participant.profile?.participantId}>
                        <td
                        
                        >
                        {participant.profile?.firstName}
                        </td>
                        <td
                                            >
                        {participant.profile?.lastName}
                        </td>
                        <td>
                        <OverlayTrigger
                            delay={100}
                            placement="left"
                            overlay={<Tooltip>{!participantIsLocked ? "Able to check in" : <> Check in locked until: <br/> {!expiryDate ? "further notice" :  expiryDate.format("yyyy/MM/DD")}</>} </Tooltip>}
                        >
                            <Button
                                variant="primary"
                                className="mr-1 mb-1"
                                style={{ 
                                    borderRadius: 22, 
                                    width: 40, 
                                    height: 40, 
                                    backgroundColor: participantIsLocked ? "red" : "green", 
                                    borderColor: participantIsLocked ? "red" : "green" 
                                }}
                                disabled={isLoading}
                                onClick={() => {
                                    setActiveParticipant(participant)
                                    setShowLockModal(true)
                                }}
                            >
                                <FontAwesomeIcon icon={!!participantIsLocked ? faLock : faLockOpen} />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            delay={100}
                            placement="left"
                            overlay={<Tooltip>{!hasActiveNote ? "There is no active note on the participant" : <> There is an active note: <br/> {participant.settings?.signInNote}</>} </Tooltip>}
                        >                        
                            <Button
                                variant="primary"
                                className="mr-1 mb-1"
                                style={{ 
                                    borderRadius: 22, 
                                    width: 40, 
                                    height: 40, 
                                    backgroundColor: hasActiveNote ? "green" : "gray", 
                                    borderColor: hasActiveNote ? "green" : "gray" 
                                }}
                                disabled={isLoading}
                                onClick={() => {
                                    setActiveParticipant(participant)
                                    setShowNoteModal(true)
                                }}
                            >
                                <FontAwesomeIcon icon={!!hasActiveNote ? faCommentDots : faCommentAlt} />
                            </Button>
                            </OverlayTrigger>

                            
                            
                        </td>
                    </tr>
                        )
                    })}

                    
                    </tbody>
                </Table>
            </>
            
        )
    }

    
}