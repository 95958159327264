import { postRequest } from "./apiCommunicationService"

/* eslint-disable no-unused-vars */
async function submitMemberIntake(data, groupId, jwt) {
  const res = await postRequest(jwt, `api/Intake/RCC/${groupId}`, data)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to submit RCC-Member intake")
  }

  return await res.json()
}

export default submitMemberIntake
