import { createSlice } from '@reduxjs/toolkit';

export const vocabulariesSlice = createSlice({
  name: 'vocabularies',
  initialState: {
    remote: null,
    local: null,
  },
  reducers: {
    setRemote: (state, action) => {
      state.remote = action.payload;
    },
    setLocal: (state, action) => {
      state.local = action.payload;
    },
  },
});

export const {
  setRemote,
  setLocal,
} = vocabulariesSlice.actions;

export default vocabulariesSlice.reducer;
