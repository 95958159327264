import { getRequest, putRequest } from "./apiCommunicationService"

/* eslint-disable import/prefer-default-export */
export async function GetRemoteVocabs(groupId, jwt) {
  const res = await getRequest(jwt, `api/Vocabularies/Remote/${groupId}`)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || 'Unable to load "remote" vocabularies')
  }

  const data = await res.json()
  return data
}

export async function GetLocalVocabs(orgId, jwt) {
  const res = await getRequest(jwt, `api/Vocabularies/Local/${orgId}`)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || 'Unable to load "local" vocabularies')
  }

  const data = await res.json()
  return data
}

export function GetByGroupKey(vocabs, groupKey) {
  return vocabs?.filter((x) => x.groupKey === groupKey)
}

export function GetValueByKey(vocabs, key) {
  return vocabs?.find((x) => x.key === key)?.value || `Key Not Defined (${key})`
}

export function GetByKey(vocabs, key) {
  return vocabs?.find((x) => x.key === key)
}

//Note: This uses admin authentication (OKTA)
export async function UpdateLocalVocabularies(requestModel, authToken) {
  const res = await putRequest(
    authToken,
    `api/Vocabularies/Local/Update`,
    requestModel
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to update Vocabularies.")
  }

  return true
}
