const jwt = require("jsonwebtoken")

export async function checkJWT(existingToken, onSetJwt) {
  try {
    let token = existingToken
    let refreshToken = true

    if (!!token) {
      var decodedToken = jwt.decode(token, { complete: true })

      //Get the current time + 2 minutes to check for expiry
      var dateNow = new Date()
      dateNow.setHours(dateNow.getHours(), dateNow.getMinutes() + 2, 0, 0)
      //If the token has expired, or is about to expire within 2 minutes: Get a new one for safety
      refreshToken = decodedToken.payload.exp < dateNow.getTime() / 1000
    }

    if (refreshToken) {
      const key = process.env.REACT_APP_KIOSK_API_KEY
      const newTokenResponse = await getRequest(
        "",
        `api/Auth/GetTokenWithApiKey/${key}`
      )

      const newToken = await newTokenResponse.json()

      token = newToken

      //Save token to state
      onSetJwt(token)
    }

    return token
  } catch (error) {
    console.error(`Unable to check JWT: ${error.message}`)
    return existingToken
  }
}

export async function getRequest(jwt, queryPath, data) {
  const kioskApiBaseUrl = process.env.REACT_APP_KIOSK_API_BASE_URL

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(data),
  }

  const res = await fetch(`${kioskApiBaseUrl}/${queryPath}`, requestOptions)

  return res
}

export async function postRequest(jwt, queryPath, data, sendFormData) {
  const kioskApiBaseUrl = process.env.REACT_APP_KIOSK_API_BASE_URL

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  }


  if (!!data) {
    if(!!sendFormData){
      requestOptions.headers = {
        Authorization: `Bearer ${jwt}`,
      }
      requestOptions.body = data
    }else{
      requestOptions.body = JSON.stringify(data)
    }
      

  }

  const res = await fetch(`${kioskApiBaseUrl}/${queryPath}`, requestOptions)

  return res
}

export async function putRequest(jwt, queryPath, data) {
  const kioskApiBaseUrl = process.env.REACT_APP_KIOSK_API_BASE_URL

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(data),
  }

  const res = await fetch(`${kioskApiBaseUrl}/${queryPath}`, requestOptions)

  return res
}
