export function isValidEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function isParticipantInactive(status){
  if(!status){
    return true;
  }
  const inactiveStatus = ["Inactive Discharged", "Inactive Referrals"]
  return !!inactiveStatus.find(s=>s===status)
}
