/// Override ServiceConsent label text for specified organizationCodes
export function getServiceConsentLabel(organizationCode) {
  let label =
    "Do you provide consent to receive services and have your personal health information stored in RecoveryLink?"

  const overrideCodes = ["BARN", "PRCC"]

  if (overrideCodes.some((x) => x === organizationCode)) {
    label =
      "Do you provide consent to have your personal information stored in RecoveryLink?"
  }

  return label
}

export function getServiceConsentHelpLabel(organizationCode) {
  let label =
    "Your support provider may request that your health information stored in RecoveryLink is shared to promote care with other providers, insurance companies, or other applicable parties. You have the right to provide or revoke consent for the electronic storage and sharing of your information."

  const overrideCodes = ["BARN", "PRCC"]

  if (overrideCodes.some((x) => x === organizationCode)) {
    label =
      "For your privacy, please confirm that you provide consent for your information to be stored electronically in RecoveryLink. Your information will not be shared without your consent."
  }

  return label
}
