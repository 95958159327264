import { configureStore } from "@reduxjs/toolkit"
import firstTimeVisitorReducer from "./reducers/firstTimeVisitor"
import activityCheckInReducer from "./reducers/activityCheckIn"
import organizationReducer from "./reducers/organization"
import routeReducer from "./reducers/route"
import vocabulariesReducer from "./reducers/vocabularies"
import actionMappingsReducer from "./reducers/actionMappings"
import adminReducer from "./reducers/admin"
import additionalAssessments from "./reducers/additionalAssesments"
import apiAuthentication from "./reducers/apiAuthentication"

export default configureStore({
  reducer: {
    firstTimeVisitor: firstTimeVisitorReducer,
    activityCheckIn: activityCheckInReducer,
    organization: organizationReducer,
    route: routeReducer,
    vocabularies: vocabulariesReducer,
    actionMappings: actionMappingsReducer,
    admin: adminReducer,
    additionalAssessments: additionalAssessments,
    apiAuthentication: apiAuthentication,
  },
})
