import {
    Button,
    Form,
} from 'react-bootstrap';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { GetByKey } from '../../services/vocabularyService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isParticipantInactive } from '../../helpers/validators';
import { useSelector } from 'react-redux';


export default function ActivityCheckInSelectItem({
    option,
    optionList,
    selection,
    isActivitySelected,
    onToggleSelection,
    getOptionOther,
    onSetOptionOther,
    errors
}) {
    const findChildren = (key) => {
        return optionList.filter((x) => x.parentId === GetByKey(optionList, key)?.id)
    }
    const { loginResponse, UUID } = useSelector((state) => state.activityCheckIn)


    //Find children
    const children = findChildren(option.key)

    const toggleSelection = () => {
        let toggleOptions = [{ ...option, isParent: children.length > 0 }]
        const isSelected = isActivitySelected(option.key)

        if (!isSelected) {
            children.forEach(child => {
                if (isActivitySelected(child.key)) {
                    const grandChildren = findChildren(child.key)

                    toggleOptions.push({ ...child, isParent: grandChildren.length > 0 })

                    //Decide if any grandChildren need to be toggled         
                    grandChildren.forEach(grandChild => {
                        if (isActivitySelected(grandChild.key)) {
                            if (!toggleOptions.some(x => x.id === grandChild.id)) {
                                toggleOptions.push(grandChild)
                            }
                        }
                    })
                }
            });
        }


        onToggleSelection(toggleOptions)
    }

    const onToggleChild = (toggleChildren) => {
        let toggleOptions = [...toggleChildren]
        let toggleParent = true

        //find my child from list
        let myChild = undefined
        toggleChildren.forEach(x => {
            if (children.filter(y => y.key === x.key).length > 0) {
                myChild = x
            }
        })

        let otherChildren = children.filter(x => x.key !== myChild.key)
        const childIsSelected = isActivitySelected(myChild.key)

        //Decide if parent should be toggled
        if (childIsSelected) {
            if (isActivitySelected(option.key)) {
                otherChildren.forEach(otherChild => {
                    if (isActivitySelected(otherChild.key)) {
                        //If there is another child selected, dont toggle prent
                        toggleParent = false
                    }
                })
            } else {
                toggleParent = false
            }

        } else if (isActivitySelected(option.key)) {
            toggleParent = false
        }

        //Decide if any grandChildren need to be toggled
        const grandChildren = findChildren(myChild.key)
        grandChildren.forEach(grandChild => {
            if (isActivitySelected(grandChild.key) === childIsSelected) {
                if (!toggleOptions.some(x => x.id === grandChild.id)) {
                    toggleOptions.push(grandChild)
                }
            }
        })

        if (toggleParent) {
            toggleOptions.push(option)
        }

        onToggleSelection(toggleOptions)
    }
    const GetButton = () => {
        return (
            <>
                <Button
                    block
                    variant=""
                    style={{
                        textAlign: 'left',
                        marginTop: 3,
                        marginBottom: 3,
                        outline: 'none !important',
                    }}
                    onClick={() => toggleSelection()}
                    disabled={option?.value === "Meet with Peer Specialist" && (isParticipantInactive(loginResponse?.status) && UUID !== '0000')}
                >
                    <table>
                        <tbody>
                            <tr valign="top">
                                <td>
                                    {
                                        isActivitySelected(option.key)
                                            ? <FontAwesomeIcon icon={faCheckSquare} size="lg" style={{ marginRight: 10, color: '#0069D9' }} />
                                            : <FontAwesomeIcon icon={faSquare} size="lg" style={{ marginRight: 10, color: 'gray' }} />
                                    }
                                </td>
                                <td>
                                    {option.value}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Button>
                {!!errors[option.key] ?
                    <div style={{ marginLeft: "50px" }}>
                        <Form.Label style={{ color: "#dc3545", fontSize: "80%", fontWeight: "400", lineHeight: "1.5px" }}>{errors[option.key]}</Form.Label>
                    </div> : null
                }


            </>

        )
    }

    if (children.length > 0) {
        return (
            <div style={{ marginLeft: "40px" }}>
                <Form.Group key={option.key}>
                    <GetButton />
                    {isActivitySelected(option.key) ?
                        children.map((child) =>
                            <ActivityCheckInSelectItem
                                option={child}
                                optionList={optionList}
                                selection={selection}
                                onToggleSelection={onToggleSelection}
                                isActivitySelected={isActivitySelected}
                                errors={errors} />) : null}

                </Form.Group>
            </div>
        )
    } else {
        return (
            <div style={{ marginLeft: "40px" }}>
                <Form.Group key={option.key}>
                    <GetButton />
                    {option.key === "activity-check-in-option-other" && isActivitySelected(option.key) ? <Form.Group className="mb-4">
                        <Form.Control
                            type="text"
                            placeholder={'Please specify "Other"'}
                            value={getOptionOther(option.key)}
                            onChange={(e) => onSetOptionOther(option.key, e.target.value)}
                            isInvalid={!!errors.otherSpecification}
                        //disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">{errors.otherSpecification}</Form.Control.Feedback>
                    </Form.Group> : null}
                </Form.Group>
            </div>
        )
    }

}