import React from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setPath } from '../../redux/reducers/route';
import KioskHeader from '../KioskHeader/KioskHeader';

function LandingPage() {
  const dispatch = useDispatch();

  return (
    <div>
      <KioskHeader />
      <p className="sub-header">
        Welcome to the Kiosk, please select an option below.
      </p>
      <br />

      <Row>
        <Col md={6}>
          <Button
            size="lg"
            variant="secondary"
            className="option-button-lg mb-2"
            block
            onClick={() => dispatch(setPath('first-time-visit/options'))}
          >
            First Time Visitor
          </Button>
        </Col>
        <Col md={6}>
          <Button
            size="lg"
            variant="secondary"
            className="option-button-lg mb-2"
            block
            onClick={() => dispatch(setPath('activity-check-in/login'))}
          >
            Activity Check In
          </Button>
        </Col>
      </Row>
    </div >
  );
}

export default LandingPage;
