import React from "react";
import {
  Button,
  ButtonGroup,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setPath } from "../../redux/reducers/route";
import { setSelectedOption } from "../../redux/reducers/firstTimeVisitor";
import KioskHeader from "../KioskHeader/KioskHeader";
import {
  GetByGroupKey,
  GetValueByKey,
} from "../../services/vocabularyService";
import { FaInfoCircle } from "react-icons/fa";

function FirstTimeVisitorOptions() {
  const dispatch = useDispatch();
  const localVocabs = useSelector((state) => state.vocabularies.local);
  const actionMappings = useSelector(
    (state) => state.actionMappings.actionMappings
  );

  return (
    <div>
      <KioskHeader />
      <p className="sub-header">
        Welcome to the Kiosk, please select an option below.
      </p>

      <Row style={{ textAlign: "center", position: "relative" }}>
        <Col md={{ span: 8, offset: 2 }}>
          <h4 className="mb-4">First Time Visitor</h4>

          {GetByGroupKey(localVocabs, "first-time-visitor-options")?.map(
            (opt) => {
              const selectedOption = opt.key;
              // Look for mapped action
              const actionMapping = actionMappings.find(
                (x) => x.sourceAction === selectedOption
              );

              const descr = localVocabs.find(
                (x) =>
                  x.parentId === opt.id &&
                  x.groupKey === "first-time-visitor-option-descriptions"
              );

              return (
                actionMapping && (
                  <ButtonGroup key={`btn-grp-${selectedOption}`} className="w-100 mb-2">
                    <Button
                      key={`btn-primary-${selectedOption}`}
                      size="lg"
                      variant="secondary"
                      className="option-button-md"
                      block
                      onClick={() => {
                        dispatch(setSelectedOption(selectedOption));
                        if (actionMapping) {
                          // Dispatch mapped action
                          dispatch(setPath(actionMapping.successTargetAction));
                        }
                      }}
                    >
                      {GetValueByKey(localVocabs, opt.key)}
                    </Button>

                    {(descr && descr.value) && (
                      <OverlayTrigger
                        trigger={["hover", "click"]}
                        placement="left"
                        key={`overlay-${selectedOption}`}
                        overlay={
                          <Popover>
                            <Popover.Content>
                              {descr.value}
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Button variant="primary">
                          <FaInfoCircle style={{ fontSize: 24 }} />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </ButtonGroup>
                )
              );
            }
          )}
        </Col>
      </Row>
    </div>
  );
}

export default FirstTimeVisitorOptions;
