import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Form,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
  faPlus,
  faSave,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { useOktaAuth } from "@okta/okta-react";
import { useToasts } from "react-toast-notifications";
import Collapse from "antd/es/collapse/index";
import { UpdateActionMappings } from "../../../services/actionMappingService";
import FirstTimeVisitorActionMappingEditModal from "./FirstTimeVisitorActionMappingEditModal";
import {
  GetByGroupKey,
} from "../../../services/vocabularyService";

const { Panel } = Collapse;

const intakeTypes = [
  { key: "first-time-visit/brief-intake", value: "Kiosk Brief Intake" },
  { key: "first-time-visit/member-intake", value: "New Member Form" },
  { key: "first-time-visit/guest-sign-up", value: "Guest Sign-Up" },
  { key: "volunteer/sign-up", value: "Volunteer Sign-Up" },
];

function FirstTimeVisitorActionMapping(props) {
  const { addToast } = useToasts();
  const { cardHeaderBGColor, localVocabs } = props;
  const { id } = useSelector((state) => state.organization.config);
  const [showEdit, setShowEdit] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [changedItems, setChangedItems] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const { authState } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const [helpTextShown, setHelpTextShown] = useState(false);

  const firstTimeVisitorOptions = GetByGroupKey(
    localVocabs,
    "first-time-visitor-options"
  );

  // Load action-mappings when ready
  const stateActionMappings = useSelector(
    (state) => state.actionMappings.actionMappings
  );
  const [localActionMappings, setLocalActionMappings] = useState(null);
  if (stateActionMappings && !localActionMappings) {
    setLocalActionMappings(
      stateActionMappings.filter(
        (x) => x.groupKey === "first-time-visitor-options"
      )
    );
  }

  // Tooltips
  const defaultShowTipDelay = 1000;
  const defaultHideTipDelay = 100;
  const [upTipDelay, setUpTipDelay] = useState(defaultShowTipDelay);
  const [addTipDelay, setAddTipDelay] = useState(defaultShowTipDelay);
  const [saveTipDelay, setSaveTipDelay] = useState(defaultShowTipDelay);
  function toggleTooltipDelay(key, show) {
    // eslint-disable-next-line default-case
    switch (key) {
      case "up":
        setUpTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;

      case "add":
        setAddTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;

      case "save":
        setSaveTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;
    }
  }

  const GetNewItem = (srcAction = undefined) => ({
    id: uuidv4(), // New Guid
    organizationId: id,
    groupKey: "first-time-visitor-options",
    sourceAction: srcAction,
    criteria: [],
    successTargetAction: undefined,
    failureTargetAction: undefined,
    new: true,
  });

  function updateEditItem(key, value) {
    const tmpEditItem = JSON.parse(JSON.stringify(editItem)); // make a local copy
    tmpEditItem[key] = value;
    setEditItem(tmpEditItem);
  }

  function addChangedItem(item, state) {
    const tmpChangedItems = JSON.parse(JSON.stringify(changedItems)).filter(
      (x) => x.id !== item.id
    ); // make a local copy

    tmpChangedItems.push({
      ...item,
      state,
    });

    setChangedItems(tmpChangedItems);
  }

  function deleteActionMappingItem(deletedItem) {
    const tmpLocalActionMappings = JSON.parse(
      JSON.stringify(localActionMappings)
    ).filter((x) => x.id !== deletedItem.id); // make a local copy

    setLocalActionMappings(tmpLocalActionMappings);
    addChangedItem(deletedItem, "deleted");
  }

  function updateLocalActionMappings(updatedItem) {
    const tmpLocalActionMappings = JSON.parse(
      JSON.stringify(localActionMappings)
    ); // make a local copy
    const tmpItem = tmpLocalActionMappings.find((x) => x.id === updatedItem.id);
    let state = "modified";

    if (tmpItem) {
      // Edit
      tmpItem.sourceAction = updatedItem.sourceAction;
      tmpItem.criteria = updatedItem.criteria;
      tmpItem.successTargetAction = updatedItem.successTargetAction;
      tmpItem.failureTargetAction = updatedItem.failureTargetAction;
      state = "modified";
    } else {
      // Add
      tmpLocalActionMappings.unshift(updatedItem);
      state = "added";
    }

    tmpLocalActionMappings.sort((a, b) =>
      a.groupKey > b.groupKey && a.key > b.key
        ? 1
        : b.groupKey > a.groupKey && b.key > a.key
          ? -1
          : 0
    );

    setLocalActionMappings(tmpLocalActionMappings);
    addChangedItem(updatedItem, state);
  }

  async function SaveChanges() {
    setLoading(true);

    try {
      await UpdateActionMappings(
        changedItems,
        authState.accessToken.accessToken
      );

      // Clear new for all
      const tmpLocalActionMappings = JSON.parse(
        JSON.stringify(localActionMappings)
      ); // make a local copy
      tmpLocalActionMappings.forEach((mapping) => {
        delete mapping.new;
      });
      setLocalActionMappings(tmpLocalActionMappings);

      // Clear changedItems
      setChangedItems([]);

      addToast("Changes saved successfully", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    } catch (error) {
      console.error(error);

      addToast("Unable to save changes. (See console for details)", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    }

    setLoading(false);
  }

  const buttonPanel = (
    <div
      style={{
        position: "fixed",
        bottom: 15,
        right: 15,
        zIndex: 999,
        textAlign: "right",
      }}
    >
      <OverlayTrigger
        delay={upTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("up", show)}
        overlay={<Tooltip>Back To Top</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="secondary"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </Button>
      </OverlayTrigger>

      <br />

      <OverlayTrigger
        delay={addTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("add", show)}
        overlay={<Tooltip>Add New Item</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="success"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            setEditItem(GetNewItem());
            setShowEdit(true);
            setAddingNew(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </OverlayTrigger>

      <br className="d-none d-md-block" />

      <OverlayTrigger
        delay={saveTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("save", show)}
        overlay={<Tooltip>Save Changes</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="primary"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            SaveChanges();
          }}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: 10, height: 22, width: 22 }}
            />
          )}
          {!loading && <FontAwesomeIcon icon={faSave} />}
        </Button>
      </OverlayTrigger>
    </div>
  );

  return (
    <div>
      {buttonPanel}

      <Collapse
        expandIconPosition="right"
        onChange={() => setHelpTextShown(!helpTextShown)}
        style={{ backgroundColor: "#AEB6BF" }}
      >
        <Panel
          header={
            <div>
              {helpTextShown ? "Hide " : "Show "}
              help-text
              <span className="ml-2" style={{ fontStyle: "italic" }}>
                (click to
                {helpTextShown ? " hide" : " show"})
              </span>
            </div>
          }
          key="1"
          style={{ borderBottom: "0.1px solid #D6D6D7" }}
        >
          <Form.Text className="text-muted" style={{ fontSize: 13 }}>
            This page allows you to setup your "First Time Visitor" options, and assign these to specific intake forms. 
            Please keep in mind that options with no configured mappings will not be visible to the end users, this gives
             you the flexibility to only show those that you need.
            <br />
            <br />
            Each option-mapping item has the following properties:
            <ul>
              <li className="mt-1">
                <b>Option: </b>
                The option for which you can configure a mapping to a specific "Intake Form".
              </li>
              <li className="mt-1">
                <b>Intake Form: </b>
                The "Intake Form" that you wan to map the selected "option" to.
              </li>
            </ul>
            This page allows you to perform the following actions per
            option-mapping item:
            <ul>
              <li className="mt-1">
                <b>Edit: </b>
                In each option-mapping entry row is an "Edit" button. This is a
                round blue button with a pencil icon. When you click this button
                the "Edit Option Mapping" popup is opened, where you can edit
                the values of the selected option-mapping item.
              </li>
              <li className="mt-1">
                <b>Delete: </b>
                In each vocabulary entry row is an "Delete" button. This is a
                round red button with a trash-can icon. When you click this
                button the "Delete Option Mapping" popup is opened, where you
                can confirm/cancel delete operation.
              </li>
              <li className="mt-1">
                <b>Add Mapping: </b>
                In each option-mapping entry row , if there is no mapping configured, 
                you will see a warning with the text "No configured mapping", 
                which if you click it, the "Add New Mapping" popup is opened 
                to add a mapping for that specific option.
              </li>
            </ul>
            In the bottom-right corner are 3 buttons that perform the following
            actions:
            <ul>
              <li className="mt-1">
                <b>Back To Top: </b>
                This is a round grey button with an up-arrow icon. When clicked,
                this simply takes you to the top of the page.
              </li>
              <li className="mt-1">
                <b>Add: </b>
                This is a round green button with a plus icon. When clicked this
                open the "Add Option Mapping" popup.
              </li>
              <li className="mt-1">
                <b>Save: </b>
                This is a round blue button with a floppy-disc icon. This is
                probably the most important button on this page. This page
                batches (collects) all changes that you make until you click
                this save button. This also gives you an added layer of
                protection against accidental changes. To undo unsaved changes,
                simply reload the page without saving.
              </li>
            </ul>
          </Form.Text>
        </Panel>
      </Collapse>
      <br />

      <div>
        <Card style={{ border: `1px solid ${cardHeaderBGColor}` }}>
          <Card.Header
            style={{
              backgroundColor: cardHeaderBGColor,
              color: "white",
              marginRight: -1,
              marginTop: -1,
            }}
          >
            <u className="ml-2">Option Mappings</u>
          </Card.Header>
          <Card.Body style={{ padding: "15px 15px 0px 15px" }}>
            {firstTimeVisitorOptions?.length === 0 && (
              <div className="mb-3">No items</div>
            )}

            {firstTimeVisitorOptions?.length > 0 && (
              <Table bordered responsive="md" style={{ marginRight: 15 }}>
                <thead>
                  <tr style={{ backgroundColor: "#AEB6BF" }}>
                    <th>Option</th>
                    <th>Intake Form</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {firstTimeVisitorOptions?.map((item) => {

                    let localActionMapping = localActionMappings?.find(
                      (x) => x.sourceAction === item.key
                    );

                    return (
                      <tr key={item.id}>
                        <td
                          style={{
                            width: "43.5%",
                            color: item.new ? "blue" : "",
                          }}
                        >
                          {item.value}
                        </td>
                        <td
                          style={{
                            width: "43.5%",
                            color: item.new ? "blue" : "",
                          }}
                        >
                          {
                            intakeTypes.find(
                              (x) =>
                                x.key === localActionMapping?.successTargetAction
                            )?.value
                          }
                          {
                            !localActionMapping &&

                            <button type="button"
                              className="btn btn-link"
                              style={{ paddingLeft: 0 }}
                              onClick={() => {
                                setEditItem(GetNewItem(item.key));
                                setShowEdit(true);
                                setAddingNew(true);
                              }}
                            >
                              Add option mapping
                            </button>

                          }
                        </td>
                        <td
                          style={{ width: "13%", color: item.new ? "blue" : "" }}
                        >
                          <Button
                            variant="primary"
                            className="mr-1 mb-1"
                            style={{ borderRadius: 22, width: 40, height: 40 }}
                            disabled={localActionMapping ? false : true}
                            onClick={() => {
                              setEditItem(localActionMapping);
                              setShowEdit(true);
                              setAddingNew(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                          <Button
                            variant="danger"
                            className="mr-1 mb-1"
                            style={{ borderRadius: 22, width: 40, height: 40 }}
                            disabled={localActionMapping ? false : true}
                            onClick={() => {
                              setEditItem(localActionMapping);
                              setShowDelete(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </div>

      <FirstTimeVisitorActionMappingEditModal
        localVocabs={localVocabs}
        showEdit={showEdit}
        addingNew={addingNew}
        setShowEdit={setShowEdit}
        editItem={editItem}
        updateEditItem={updateEditItem}
        updateLocalActionMappings={updateLocalActionMappings}
        requiredFields={["sourceAction", "successTargetAction"]}
        intakeTypes={intakeTypes}
        localActrionMappings={localActionMappings}
      />

      <FirstTimeVisitorActionMappingEditModal
        localVocabs={localVocabs}
        showDelete={showDelete}
        addingNew={false}
        setShowDelete={setShowDelete}
        editItem={editItem}
        deleteActionMappingItem={deleteActionMappingItem}
        intakeTypes={intakeTypes}
      />
    </div>
  );
}

export default FirstTimeVisitorActionMapping;
