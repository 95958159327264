import { getRequest, putRequest } from "./apiCommunicationService"

/* eslint-disable no-unused-vars */
export async function SubmitAdditionalAssestmentConfig(data, authToken) {
  const res = await putRequest(
    authToken,
    `api/AdditionalAssestmentConfig/UpdateAdditionalAssessmentConfig`,
    data
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to submit Additional Assestment Config")
  }

  return true
}

export async function GetAdditionalAsessmentConfig(orgId, jwt) {
  const res = await getRequest(
    jwt,
    `api/AdditionalAssestmentConfig/GetAdditionalAssessmentConfig/${orgId}`
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to get Additional Assessments")
  }

  const data = await res.json()

  return data
}
