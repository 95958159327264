import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "antd/es/select/index";
import CustomErrorFeedback from "../../CustomErrorFeedback/CustomErrorFeedback";
const { Option } = Select;

function AdditionalAssessmentConfigurationModal(props) {
  const {
    forms,
    steps,
    showEdit,
    setShowEdit,
    editItem,
    updateEditItem,
    updateLocalAdditionalAssesments,
    showDelete,
    setShowDelete,
    deleteAdditionalAssessmentConfig,
    addingNew,
    localAdditionalAssessments,
  } = props;

  const [errors, setErrors] = useState({});

  function clearError(key) {
    delete errors[key];
    setErrors(errors);
  }

  function Validate() {
    const requiredMessage = "A selection is required.";
    const validationErrors = {};

    // Additional Form
    if (
      props.requiredFields?.includes("additionalForm") &&
      !editItem?.additionalForm
    ) {
      validationErrors.additionalForm = requiredMessage;
    }
    // description
    if (
      props.requiredFields?.includes("description") &&
      !editItem?.description
    ) {
      validationErrors.description = requiredMessage;
    }
    // step
    if (props.requiredFields?.includes("step") && !editItem?.step) {
      validationErrors.description = requiredMessage;
    }
    // toggle
    if (
      props.requiredFields?.includes("toggle") &&
      editItem?.toggle === undefined
    ) {
      validationErrors.toggle = requiredMessage;
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  }

  function Save() {
    if (Validate()) {
      setShowEdit(false);
      updateLocalAdditionalAssesments(editItem);
    } else {
      // ...
    }
  }
  return (
    <Modal show={showEdit || showDelete} onHide={() => null} centered>
      <Modal.Header>
        <Modal.Title>
          {showEdit && !showDelete && (
            <>
              {editItem?.new ? "Add " : "Edit "}
              Additional Assessment
            </>
          )}
          {!showEdit && showDelete && (
            <>Are you sure you want to delete this item?</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Additional Assessment Form</Form.Label>
                <Select
                  disabled={showDelete || !addingNew}
                  size="large"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  value={editItem?.additionalForm}
                  onChange={(value) => {
                    updateEditItem("additionalForm", value);
                    clearError("additionalForm");
                  }}
                >
                  {localAdditionalAssessments?.length > 0
                    ? forms?.map((item) => {
                        return (
                          localAdditionalAssessments?.find(
                            (x) => x.additionalForm !== item.key
                          ) && (
                            <Option key={item.key} value={item.key}>
                              {item.value}
                            </Option>
                          )
                        );
                      })
                    : forms?.map((item) => {
                        return (
                          <Option key={item.key} value={item.key}>
                            {item.value}
                          </Option>
                        );
                      })}
                </Select>
                <CustomErrorFeedback message={errors.additionalForm} />
                <Form.Text className="text-muted" hidden={showDelete}>
                  Please select the addional assessment form that should be
                  completed.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  disabled={showDelete}
                  type="text"
                  value={editItem?.description}
                  onChange={(e) => {
                    updateEditItem("description", e.target.value);
                    clearError("description");
                  }}
                />
                <CustomErrorFeedback message={errors.description} />
                <Form.Text className="text-muted" hidden={showDelete}>
                  Please enter the text you would like to show to the
                  participant when prompted to fill out the additional
                  assessment.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Perform At</Form.Label>
                <Select
                  mode={"multiple"}
                  disabled={showDelete}
                  size="large"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  value={editItem?.step}
                  onChange={(value) => {
                    updateEditItem("step", value);
                    clearError("step");
                  }}
                  options={steps}
                />
                <CustomErrorFeedback message={errors.step} />
                <Form.Text className="text-muted" hidden={showDelete}>
                  Please select when the addional assessment form should be
                  completed.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Enabled</Form.Label>
                <Form.Check
                  disabled={showDelete}
                  label="Yes"
                  type="radio"
                  name="toggle"
                  id="on"
                  checked={editItem?.toggle}
                  onChange={(e) => {
                    updateEditItem("toggle", true);
                    clearError("toggle");
                  }}
                />
                <Form.Check
                  disabled={showDelete}
                  label="No"
                  type="radio"
                  name="toggle"
                  id="off"
                  checked={!editItem?.toggle}
                  onChange={(e) => {
                    updateEditItem("toggle", false);
                    clearError("toggle");
                  }}
                />
                <CustomErrorFeedback message={errors.toggle} />
                <Form.Text className="text-muted" hidden={showDelete}>
                  Please select if the additional assessment is on/off.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={showDelete ? "danger" : "primary"}
          onClick={() => {
            setErrors({}); // Clear Errors
            if (showEdit && !showDelete) {
              Save();
            } else {
              setShowDelete(false);
              deleteAdditionalAssessmentConfig(editItem);
            }
          }}
          style={{ width: 100 }}
        >
          Ok
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setErrors({}); // Clear Errors
            if (setShowEdit) setShowEdit(false);
            if (setShowDelete) setShowDelete(false);
          }}
          style={{ width: 100 }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AdditionalAssessmentConfigurationModal;
