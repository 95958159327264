import { postRequest } from "./apiCommunicationService"

/* eslint-disable no-unused-vars */
async function submitGuestIntake(data, groupId, jwt) {
  const res = await postRequest(jwt, `api/Intake/Guest/${groupId}`, data)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to submit Guest intake")
  }

  return true
}

export default submitGuestIntake
