import { postRequest } from "./apiCommunicationService"

/* eslint-disable no-unused-vars */
async function submitVolunteerSignUp(data, jwt) {
  const res = await postRequest(jwt, `api/Volunteering/SignUp`, data)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to submit Volunteering-Sign-Up")
  }

  return true
}

export default submitVolunteerSignUp
