import React from 'react';
import { Image } from 'react-bootstrap';

import lock from '../../images/lock.png';

function Unauthorized() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '15vh' }}>
      <Image src={lock} rounded width={128} />
      <h3 className="mt-2" style={{ color: '#134563' }}>
        {/* You are not authorized to access this page */}
        Sorry, but you don't have access to this page.
      </h3>
      <h5 className="mt-4" style={{ color: '#134563', fontStyle: 'italic' }}>
        Contact your administrator if you think this may be a mistake.
      </h5>
    </div>
  );
}

export default Unauthorized;
