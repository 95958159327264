/* eslint-disable indent */
import React, { useState } from "react";
import {
  Button,
  Card,
  Form,
  OverlayTrigger,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Checkbox } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faPencilAlt,
  // faTrashAlt,
  // faPlus,
  faSave,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { useToasts } from "react-toast-notifications";
import { useOktaAuth } from "@okta/okta-react";
import * as changeCase from "change-case";
import { UpdateOrgIntakeCustomizations } from "../../../services/organizationService";

function IntakeCustomization(props) {
  const { cardHeaderBGColor, intakeCustomizations, setIntakeCustomizations } =
    props;
  const { addToast } = useToasts();
  const { authState } = useOktaAuth();

  // Local state vars
  const [loading, setLoading] = useState(false);
  const [changedItems, setChangedItems] = useState([]);
  const defaultShowTipDelay = 1000;
  const defaultHideTipDelay = 100;
  const [upTipDelay, setUpTipDelay] = useState(defaultShowTipDelay);
  // const [addTipDelay, setAddTipDelay] = useState(defaultShowTipDelay);
  const [saveTipDelay, setSaveTipDelay] = useState(defaultShowTipDelay);

  function toggleTooltipDelay(key, show) {
    // eslint-disable-next-line default-case
    switch (key) {
      case "up":
        setUpTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;

      // case 'add':
      //   setAddTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
      //   break;

      case "save":
        setSaveTipDelay(show ? defaultHideTipDelay : defaultShowTipDelay);
        break;
    }
  }

  function addChangedItem(item, state) {
    const tmpChangedItems = JSON.parse(JSON.stringify(changedItems)).filter(
      (x) => x.id !== item.id
    ); // make a local copy

    tmpChangedItems.push({
      ...item,
      state,
    });

    setChangedItems(tmpChangedItems);
  }

  function updateCustomizationItem(itemId, updateKey, value) {
    const tmpIntakeCustomizations = JSON.parse(
      JSON.stringify(intakeCustomizations)
    );
    const updateItem = tmpIntakeCustomizations.find((x) => x.id === itemId);
    if (updateItem) {
      updateItem[updateKey] = value;
    }

    //It does not make sense to set a field as required and leave show unchecked
    //so now, if the user sets a field as required, the show will automatically be checked.
    if (updateItem["required"] === true && updateKey === "required") {
      updateItem["show"] = true;
    }

    //same the other way around, if the user unchecks the show checkbox, the required will be unchecked
    if (updateItem["show"] === false && updateKey === "show") {
      updateItem["required"] = false;
    }

    setIntakeCustomizations(tmpIntakeCustomizations);
    addChangedItem(updateItem, "modified");
  }

  //this validates that at least one of the phone and email fields are required, for each of the forms
  // function validateAtLeastOneEmailOrPhoneFieldsRequired() {

  //   // for kiosk brief intake
  //   let kioskIntake_atLeastOneRequired = intakeCustomizations?.filter((x) => x.intake === 'kiosk-brief-intake' && x.required && x.show && ((x.field === 'email') || (x.field === 'phone')))?.length > 0;
  //   // for rcc intake
  //   let rccIntake_atLeastOneRequired = intakeCustomizations?.filter((x) => x.intake === 'rcc-intake' && x.required && x.show && ((x.field === 'email') || (x.field === 'phone')))?.length > 0;
  //   // for volunteer signup
  //   let volunteerSignUp_atLeastOneRequired = intakeCustomizations?.filter((x) => x.intake === 'volunteer-sign-up' && x.required && x.show && ((x.field === 'email') || (x.field === 'phone')))?.length > 0;

  //   let formswithTheIssue = [];

  //   if (!kioskIntake_atLeastOneRequired) {
  //     formswithTheIssue.push("Kiosk Brief Intake");
  //   }
  //   if (!rccIntake_atLeastOneRequired) {
  //     formswithTheIssue.push("New Member Form");
  //   }
  //   if (!volunteerSignUp_atLeastOneRequired) {
  //     formswithTheIssue.push("Volunteer Sign-Up");
  //   }

  //   if (formswithTheIssue.length > 0) {

  //     addToast(
  //       'Either Email or Phone have to be shown and required for identification purposes. Please set at least one of these as required in the following forms: ' + formswithTheIssue.join(", "),
  //       {
  //         appearance: 'error',
  //         autoDismiss: true,
  //         autoDismissTimeout: 10000,
  //       },
  //     );

  //     return false;
  //   }

  //   return true;
  // }

  async function SaveChanges() {
    try {
      setLoading(true);

      // if (validateAtLeastOneEmailOrPhoneFieldsRequired()) {
      await UpdateOrgIntakeCustomizations(
        changedItems,
        authState.accessToken.accessToken
      );

      addToast("Changes saved successfully", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
      // }
    } catch (error) {
      console.error(error);

      addToast("Unable to save changes. (See console for details)", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 10000,
      });
    }

    setLoading(false);
  }

  const buttonPanel = (
    <div
      style={{
        position: "fixed",
        bottom: 15,
        right: 15,
        zIndex: 999,
        textAlign: "right",
      }}
    >
      <OverlayTrigger
        delay={upTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("up", show)}
        overlay={<Tooltip>Back To Top</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="secondary"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </Button>
      </OverlayTrigger>

      {/* <br />

      <OverlayTrigger
        delay={addTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay('add', show)}
        overlay={(
          <Tooltip>
            Add New Item
          </Tooltip>
        )}
      >
        <Button
          className="mr-1 mb-1"
          variant="success"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            setEditItem(GetNewItem());
            setShowEdit(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </OverlayTrigger> */}

      <br className="d-none d-md-block" />

      <OverlayTrigger
        delay={saveTipDelay}
        placement="left"
        onToggle={(show) => toggleTooltipDelay("save", show)}
        overlay={<Tooltip>Save Changes</Tooltip>}
      >
        <Button
          className="mr-1 mb-1"
          variant="primary"
          style={{ borderRadius: 25, width: 50, height: 50 }}
          onClick={() => {
            SaveChanges();
          }}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: 10, height: 22, width: 22 }}
            />
          )}
          {!loading && <FontAwesomeIcon icon={faSave} />}
        </Button>
      </OverlayTrigger>
    </div>
  );

  return (
    <div>
      {buttonPanel}

      <Form.Text className="text-muted">
        This section allows you to override default settings for selected intake
        fields.
      </Form.Text>
      <Form.Text className="text-muted">
        For fields you would like required, please ensure that the "required"
        field is selected in the corresponding intake or interest form.
      </Form.Text>
      <Form.Text className="text-muted">
        If you would like any form to require either phone OR email, please
        deselect the required option on both phone and email. This will have the
        system require at least one of either variable to be input by the end
        user.
      </Form.Text>
      <br />

      <Card style={{ border: `1px solid ${cardHeaderBGColor}` }}>
        <Card.Header
          style={{
            backgroundColor: cardHeaderBGColor,
            color: "white",
            marginRight: -1,
            marginTop: -1,
          }}
        >
          Kiosk Brief Intake
        </Card.Header>
        <Card.Body>
          {intakeCustomizations?.filter(
            (x) => x.intake === "kiosk-brief-intake"
          )?.length > 0 && (
            <Table bordered responsive="md" style={{ marginRight: 15 }}>
              <thead>
                <tr style={{ backgroundColor: "#AEB6BF" }}>
                  <th>Field</th>
                  <th>Required</th>
                  <th>Show</th>
                </tr>
              </thead>
              <tbody>
                {intakeCustomizations
                  ?.filter((x) => x.intake === "kiosk-brief-intake")
                  .sort((a, b) => a.order - b.order)
                  .map((item) => (
                    <tr key={item.id}>
                      <td
                        style={{ width: "50%", color: item.new ? "blue" : "" }}
                      >
                        {changeCase.capitalCase(item.label)}
                      </td>
                      <td
                        style={{ width: "25%", color: item.new ? "blue" : "" }}
                      >
                        <Checkbox
                          disabled={item.locked.includes("required")}
                          checked={item.required}
                          onChange={(e) =>
                            updateCustomizationItem(
                              item.id,
                              "required",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                      <td
                        style={{ width: "25%", color: item.new ? "blue" : "" }}
                      >
                        <Checkbox
                          disabled={item.locked.includes("show")}
                          checked={item.show}
                          onChange={(e) =>
                            updateCustomizationItem(
                              item.id,
                              "show",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {intakeCustomizations?.filter(
            (x) => x.intake === "kiosk-brief-intake"
          )?.length === 0 && <div className="mb-2">No items</div>}
        </Card.Body>
      </Card>

      <br />

      <Card style={{ border: `1px solid ${cardHeaderBGColor}` }}>
        <Card.Header
          style={{
            backgroundColor: cardHeaderBGColor,
            color: "white",
            marginRight: -1,
            marginTop: -1,
          }}
        >
          New Member Form
        </Card.Header>
        <Card.Body>
          {intakeCustomizations?.filter((x) => x.intake === "rcc-intake")
            ?.length > 0 && (
            <Table bordered responsive="md" style={{ marginRight: 15 }}>
              <thead>
                <tr style={{ backgroundColor: "#AEB6BF" }}>
                  <th>Field</th>
                  <th>Required</th>
                  <th>Show</th>
                </tr>
              </thead>
              <tbody>
                {intakeCustomizations
                  ?.filter((x) => x.intake === "rcc-intake")
                  .sort((a, b) => a.order - b.order)
                  .map((item) => (
                    <tr key={item.id}>
                      <td
                        style={{ width: "50%", color: item.new ? "blue" : "" }}
                      >
                        {changeCase.capitalCase(item.label)}
                      </td>
                      <td
                        style={{ width: "25%", color: item.new ? "blue" : "" }}
                      >
                        <Checkbox
                          disabled={item.locked.includes("required")}
                          checked={item.required}
                          onChange={(e) =>
                            updateCustomizationItem(
                              item.id,
                              "required",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                      <td
                        style={{ width: "25%", color: item.new ? "blue" : "" }}
                      >
                        <Checkbox
                          disabled={item.locked.includes("show")}
                          checked={item.show}
                          onChange={(e) =>
                            updateCustomizationItem(
                              item.id,
                              "show",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {intakeCustomizations?.filter((x) => x.intake === "rcc-intake")
            ?.length === 0 && <div className="mb-2">No items</div>}
        </Card.Body>
      </Card>

      <br />

      <Card style={{ border: `1px solid ${cardHeaderBGColor}` }}>
        <Card.Header
          style={{
            backgroundColor: cardHeaderBGColor,
            color: "white",
            marginRight: -1,
            marginTop: -1,
          }}
        >
          Volunteer Sign-Up
        </Card.Header>
        <Card.Body>
          {intakeCustomizations?.filter((x) => x.intake === "volunteer-sign-up")
            ?.length > 0 && (
            <Table bordered responsive="md" style={{ marginRight: 15 }}>
              <thead>
                <tr style={{ backgroundColor: "#AEB6BF" }}>
                  <th>Field</th>
                  <th>Required</th>
                  <th>Show</th>
                </tr>
              </thead>
              <tbody>
                {intakeCustomizations
                  ?.filter((x) => x.intake === "volunteer-sign-up")
                  .sort((a, b) => a.order - b.order)
                  .map((item) => (
                    <tr key={item.id}>
                      <td
                        style={{ width: "50%", color: item.new ? "blue" : "" }}
                      >
                        {changeCase.capitalCase(item.label)}
                      </td>
                      <td
                        style={{ width: "25%", color: item.new ? "blue" : "" }}
                      >
                        <Checkbox
                          disabled={item.locked.includes("required")}
                          checked={item.required}
                          onChange={(e) =>
                            updateCustomizationItem(
                              item.id,
                              "required",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                      <td
                        style={{ width: "25%", color: item.new ? "blue" : "" }}
                      >
                        <Checkbox
                          disabled={item.locked.includes("show")}
                          checked={item.show}
                          onChange={(e) =>
                            updateCustomizationItem(
                              item.id,
                              "show",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {intakeCustomizations?.filter((x) => x.intake === "volunteer-sign-up")
            ?.length === 0 && <div className="mb-2">No items</div>}
        </Card.Body>
      </Card>

      <br />

      <Card style={{ border: `1px solid ${cardHeaderBGColor}` }}>
        <Card.Header
          style={{
            backgroundColor: cardHeaderBGColor,
            color: "white",
            marginRight: -1,
            marginTop: -1,
          }}
        >
          Guest Sign-Up
        </Card.Header>
        <Card.Body>
          {intakeCustomizations?.filter((x) => x.intake === "guest-sign-up")
            ?.length > 0 && (
            <Table bordered responsive="md" style={{ marginRight: 15 }}>
              <thead>
                <tr style={{ backgroundColor: "#AEB6BF" }}>
                  <th>Field</th>
                  <th>Required</th>
                  <th>Show</th>
                </tr>
              </thead>
              <tbody>
                {intakeCustomizations
                  ?.filter((x) => x.intake === "guest-sign-up")
                  .sort((a, b) => a.order - b.order)
                  .map((item) => (
                    <tr key={item.id}>
                      <td
                        style={{ width: "50%", color: item.new ? "blue" : "" }}
                      >
                        {changeCase.capitalCase(item.label)}
                      </td>
                      <td
                        style={{ width: "25%", color: item.new ? "blue" : "" }}
                      >
                        <Checkbox
                          disabled={item.locked.includes("required")}
                          checked={item.required}
                          onChange={(e) =>
                            updateCustomizationItem(
                              item.id,
                              "required",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                      <td
                        style={{ width: "25%", color: item.new ? "blue" : "" }}
                      >
                        <Checkbox
                          disabled={item.locked.includes("show")}
                          checked={item.show}
                          onChange={(e) =>
                            updateCustomizationItem(
                              item.id,
                              "show",
                              e.target.checked
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {intakeCustomizations?.filter((x) => x.intake === "guest-sign-up")
            ?.length === 0 && <div className="mb-2">No items</div>}
        </Card.Body>
      </Card>
    </div>
  );
}

export default IntakeCustomization;
