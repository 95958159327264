import { postRequest } from "./apiCommunicationService"

async function submitBriefIntake(data, groupId, jwt) {
  const res = await postRequest(jwt, `api/Intake/Kiosk/${groupId}`, data)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to submit Kiosk-Brief intake")
  }

  return await res.json()
}

export default submitBriefIntake
