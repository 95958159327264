import { getRequest, putRequest } from "./apiCommunicationService"

export async function GetActionMappings(orgId, jwt) {
  const res = await getRequest(jwt, `api/ActionMapping/${orgId}`)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to load action mappings")
  }

  const data = await res.json()
  return data
}

//Note: This uses admin authentication (OKTA)
export async function UpdateActionMappings(requestModel, authToken) {
  const res = await putRequest(
    authToken,
    "api/ActionMapping/Update",
    requestModel
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to update action mappings.")
  }

  return true
}
