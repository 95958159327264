import { getRequest, putRequest, postRequest } from "./apiCommunicationService"

export async function GetOrgConfig(code, jwt) {
  const res = await getRequest(jwt, `api/Organization/GetConfig/${code}`)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to load organization configuration")
  }

  if (res.status === 204) {
    return false
  }

  const data = await res.json()
  return data
}

export async function GetOrgLocations(orgId, jwt) {
  const res = await getRequest(jwt, `api/Organization/GetLocations/${orgId}`)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to load organization locations")
  }

  if (res.status === 204) {
    return false
  }

  const data = await res.json()
  return data
}

export async function GetOrgEmailConfig(orgId, jwt) {
  const res = await getRequest(jwt, `api/Organization/GetEmailConfig/${orgId}`)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(
      resText || "Unable to load organization email configuration"
    )
  }

  if (res.status === 204) {
    return false
  }

  const data = await res.json()
  return data
}

//Note: This uses admin authentication (OKTA)
export async function UpdateOrganization(requestModel, authToken) {
  const res = await putRequest(
    authToken,
    `api/Organization/Update`,
    requestModel
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to update Organization.")
  }

  return true
}

//Note: This uses admin authentication (OKTA)
export async function UploadOrgLogo(requestModel, authToken) {
  const res = await postRequest(
    authToken,
    `api/Organization/UploadLogo`,
    requestModel, 
    true
  )

  const resText = await res.text()

  if (!res.ok) {
    throw new Error(resText || "Unable to upload Organization logo.")
  }

  return resText
}

//Note: This uses admin authentication (OKTA)
export async function UpdateEmailSettings(requestModel, authToken) {
  const res = await putRequest(
    authToken,
    `api/Organization/UpdateEmailConfig`,
    requestModel
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(resText || "Unable to update Organization Email Settings.")
  }

  return true
}

export async function GetOrgIntakeCustomizations(orgId, jwt) {
  const res = await getRequest(jwt, `api/Intake/Customizations/${orgId}`)

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(
      resText || "Unable to load Organization intake customizations."
    )
  }

  if (res.status === 204) {
    return false
  }

  const data = await res.json()
  return data
}

//Note: This uses admin authentication (OKTA)
export async function UpdateOrgIntakeCustomizations(requestModel, authToken) {
  const res = await putRequest(
    authToken,
    `api/Intake/Customizations/Update`,
    requestModel
  )

  if (!res.ok) {
    const resText = await res.text()
    throw new Error(
      resText || "Unable to update Organization intake customizations."
    )
  }

  return true
}

//this method was added as a helper to decide wether a field defined on IntakeCustomization is required or not
//if the field is set as show = false, then is not required,  otherwise we check it's "required" property.
function IsRequired_BasedOnIntakeCustomization(data, key, intake) {
  const item = data?.find((x) => x.intake === intake && x.field === key)
  if (!item) return false
  if (item["show"] === false) return false
  return item["required"]
}

export function IsRequired_BriefIntakeField(data, key) {
  return IsRequired_BasedOnIntakeCustomization(data, key, "kiosk-brief-intake")
}

export function IsRequired_RCCIntakeField(data, key) {
  return IsRequired_BasedOnIntakeCustomization(data, key, "rcc-intake")
}

export function IsRequired_GuestIntakeField(data, key) {
  return IsRequired_BasedOnIntakeCustomization(data, key, "guest-sign-up")
}

export function IsRequired_VolunteerSignupField(data, key) {
  return IsRequired_BasedOnIntakeCustomization(data, key, "volunteer-sign-up")
}
