import { createSlice } from "@reduxjs/toolkit"

export const apiAuthorizationSlice = createSlice({
  name: "piAuthorization",
  initialState: {
    token: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
  },
})

export const { setToken } = apiAuthorizationSlice.actions

export default apiAuthorizationSlice.reducer
