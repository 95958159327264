import React from 'react';
import { Container } from 'react-bootstrap';
import TopNav from '../Navbar/TopNav';

function Layout(props) {
  return (
    <div className="app">
      <TopNav />
      <Container
        // fluid
        style={{
          paddingTop: 85,
          paddingBottom: 40,
          minHeight: '91.5vh',
        }}
      >
        {props.children}
      </Container>
      <footer className="footer">
        © 2021 - RecoveryLink Kiosk
      </footer>
    </div>
  );
}

export default Layout;
