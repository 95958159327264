import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { setPath } from '../../redux/reducers/route';
import { setConfig, setLocations, setActiveLocation, setActiveLocationName, setIntakeCustomizations } from '../../redux/reducers/organization';
import { GetOrgConfig, GetOrgLocations, GetOrgIntakeCustomizations } from '../../services/organizationService';
import { setRemote, setLocal } from '../../redux/reducers/vocabularies';
import { setActionMappings } from '../../redux/reducers/actionMappings';
import { GetRemoteVocabs, GetLocalVocabs, GetValueByKey } from '../../services/vocabularyService';
import { GetActionMappings } from '../../services/actionMappingService';
import { checkJWT } from '../../services/apiCommunicationService';
import { setToken } from "../../redux/reducers/apiAuthentication"
import { getLocationCookieValues } from '../../helpers/cookies';

function OrgConfigLoad() {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const localVocabs = useSelector((state) => state.vocabularies.local);
  const jwt = useSelector((state) => state.apiAuthentication.token);
  const [cookies, setCookie] = useCookies();

async function loadLocations(orgID, newLocalVocabs){
  try {
    const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
    const locations = await GetOrgLocations(orgID, localJWT);
    dispatch(setLocations(locations));

    if(!!locations && locations.length > 0){
      //First check if a cookie exists and if it is found in the locations list
      //If not found, check if there is only one location, select it if so
      //If multiples found that also do not match the cookie, let user select
      if (cookies.kiosk_location && cookies.kiosk_location !== 'undefined' && locations.some(location => location.id === cookies.kiosk_location)) {
        let location = locations.find(location => location.id === cookies.kiosk_location)

        if(!!location){
          dispatch(setActiveLocation(cookies.kiosk_location));
          dispatch(setActiveLocationName(location?.name));
          return false
        }        
      }
      
      if(locations.length === 1){
        dispatch(setActiveLocation(locations[0].id));
        dispatch(setActiveLocationName(locations[0].name));
        const locationCookie = getLocationCookieValues(locations[0].id)
        setCookie(locationCookie.name, locationCookie.value, { expires: locationCookie.expiration });
      }else{
        //Notify calling code that location selection is required
        return true
      }
    }else{
      //Notify calling code that location selection is not required.
      dispatch(setActiveLocation(""));
      dispatch(setActiveLocationName(""));
    }

    return false
  }
  catch (error) {
    console.error(error);
    addToast(
      GetValueByKey(!!localVocabs ? localVocabs: newLocalVocabs, 'organization-locations-load-error'),
      {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 10000,
      },
    );
  }
}

  async function loadRemoteVocabs(groupId) {
    // Load "remote" vocabs
    try {
      const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
      const vocabs = await GetRemoteVocabs(groupId, localJWT);
      dispatch(setRemote(vocabs.terms));
    }
    catch (error) {
      console.error(error);
      addToast(
        GetValueByKey(localVocabs, 'remote-vocabs-load-error'),
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );
    }
  }

  async function loadLocalVocabs(orgId) {
    // Load "local" vocabs
    try {
      const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
      const vocabs = await GetLocalVocabs(orgId, localJWT);
      dispatch(setLocal(vocabs));
      return vocabs
    }
    catch (error) {
      console.error(error);
      addToast(
        GetValueByKey(localVocabs, 'local-vocabs-load-error'),
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );
    }
  }

  async function loadActionMappings(orgId) {
    // Load action-mappings
    try {
      const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
      const actionMappings = await GetActionMappings(orgId, localJWT);
      dispatch(setActionMappings(actionMappings));
    }
    catch (error) {
      console.error(error);
      addToast(
        'Unable to load action-mappings. (See console for details)',
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );
    }
  }

  async function loadIntakeCustomizations(orgId) {
    try {
      const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})
      const intakeCustomizations = await GetOrgIntakeCustomizations(orgId, localJWT);
      dispatch(setIntakeCustomizations(intakeCustomizations));
    }
    catch (error) {
      console.error(error);
      addToast(
        'Unable to load intake-customizations. (See console for details)',
        {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 10000,
        },
      );
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      const orgCode = cookies.kiosk_unlocked;
      const localJWT = await checkJWT(jwt, (newJwt) => {dispatch(setToken(newJwt));})

      // Load Organization Name and Logo
      const config = await GetOrgConfig(orgCode, localJWT);
      if (config === false) {
        dispatch(setPath('organization/not-found'));
      }
      else {
        dispatch(setConfig(config));

        await loadRemoteVocabs(config.groupId);
        const newLocalVocab = await loadLocalVocabs(config.id);
        const selectLocation = await loadLocations(config.orgUuid, newLocalVocab)
        await loadActionMappings(config.id);
        await loadIntakeCustomizations(config.id);

        if(selectLocation){
          dispatch(setPath('organization/location'));
        }else{
          dispatch(setPath('home'));
        }
      }
    }
    catch (error) {
      console.error(error);
      dispatch(setPath('organization/load-error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div style={{ textAlign: 'center', paddingTop: '24vh' }}>
      <Spinner animation="border" style={{ width: 64, height: 64, marginBottom: 20 }} />
      <h3>Loading Organization Configuration</h3>
    </div>
  );
}

export default OrgConfigLoad;
